import React,{FC,useEffect,useState,useRef} from 'react'
import { useLoadingContext } from "react-router-loading";
import { Link } from "react-router-dom";
import {addLoadedL, addLoad3,actualLang,addLang,addLoaded ,actualDefLang,addDefLang} from '../features/counter/counterSlice';
import { useAppDispatch,useAppSelector } from '../app/hooks';
const StepOne:FC<any> = ({updateURL,step1Btn,setActiveLocal}) => {
   const defLang=useAppSelector(actualDefLang)
   const dispatch = useAppDispatch()
   const loadingContext = useLoadingContext();
   let localLang:string = window.navigator.language
   const loading = async () => {
      dispatch(addLoadedL(true))
      // loading some data
      console.log('scroll1')
      window.scrollTo(0,0)
      // call method to indicate that loading is done and we are ready to switch
      loadingContext.done();
   };
   
   const[steptwo,setSteptwo]=useState(false)
   const lang = useAppSelector(actualLang)
   const toggleLang=(e:any)=>{
      
      // localStorage.removeItem('lang')
      // localStorage.setItem('lang', `${e.target.id}`)
      //dispatch(addLoaded(false))
     // dispatch(addLang(localStorage.getItem('lang')?.includes('RUSSIAN')?'RUSSIAN':'ENGLISH'))
      setReady(false)
      //console.log(localStorage.getItem('lang'))
      //updateURL()


      let wls:any=window
      .location
      .search
      .replace('?lang=','').replace('code=','').split('&')
      
      let value = e.target.id
      let l =value.length? value : wls[0].length&&wls[0]
      //localStorage.setItem('lang',l?l:localLang.includes('ru')?'RUSSIAN':'ENGLISH')
      
      //setActiveLocal(value)
      updateURL(value,'')
   }
   const[step1,setStep1]=useState<any>()
   
   const [loadStep1,setLoadStep1]=useState(false)
   const [ready,setReady]=useState(true)
   //promocode func 

   useEffect(()=>{
      dispatch(addLoadedL(false))
   },[])

   useEffect(()=>{
      


      let wls:any=window
      .location
      .search
      .replace('?lang=','').replace('code=','').split('&')
      
         let ifl=wls[0]
         let l =(wls[0].length&&ifl!==null)?ifl:defLang
         //console.log(defLang)
            //updateURL(localStorage.getItem('lang')?localStorage.getItem('lang'):l,'')
         
      

      dispatch(addLoad3(false))
      
      if(ready&&!steptwo&&lang){
         let url =`https://promocodes.themultiform.com/app/api/local?populate[buttons][populate][button][populate][img][fields]=url&locale=${lang}`
         
         fetch(url)
         
            .then((res)=>res.json())
            .then((result)=>{
               
               setStep1(result.data.attributes)
               
            })
            
            .then(()=>{
               setLoadStep1(true)
            })
            .then(()=>{
               if(localStorage.getItem('step')){
                  setSteptwo(true)
                  setTimeout(() => {
                     link.current?.click()
                  }, 100);
               }else
               loading()
            })
            .catch((err)=>{console.log(err)})
      
         }

            
      
         
      
   
   
   },[lang])
const link = useRef<any>(null)
   //updateURL
// function updateURL() {
//    let wls:any=window
//    .location
//    .search
//    .replace('?lang=','').replace('code=','').split('&')
// console.log(wls)

// if (window.history.replaceState) {
//    let l = wls[0].length&&wls[0].includes('RUSSIAN')?'RUSSIAN':'ENGLISH'
//    dispatch(addLang(l?l:localLang.includes('RUSSIAN')?'RUSSIAN':'ENGLISH'))

//    var baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
//    var newUrl = baseUrl + `?lang=${l?l:localLang.includes('RUSSIAN')?'RUSSIAN':'ENGLISH'}`;
//    window.history.replaceState(null,'', newUrl);
//    if(!localStorage.getItem('lang'))
//    localStorage.setItem('lang',l)
// }
// else {
//    console.warn('History API не поддерживается');
// }
// }

// {step1Btn&&
//    step1Btn.map((item:any,index:any)=>(
//       <div key={index} className='p-1 w-full'>
//          <button onClick={(e)=>toggleLang(e)} id={item.local}  className='w-full hover:bg-green duration-300  localBtn py-3   border-green flex items-center justify-center font-extrabold uppercase '>
//          {/* <img className='mr-2 pointer-events-none' src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="lang" /> sm:first:mr-3 sm:first:mb-0 first:mb-4 sm:last:ml-3*/}
//             <span className='pointer-events-none'>{item}</span>
//          </button>

//       </div>
      
//    ))
// } 


// {step1&&
                  
//    step1.buttons.button.map((item:any,index:any)=>(
//       <Link key={item.id} ref={link} to='/step_two' onClick={(e)=>toggleLang(e)} id={item.local}  className='sm:first:mr-3 sm:first:mb-0 first:mb-4 sm:last:ml-3 w-40 hover:bg-green duration-300  localBtn py-3   border-green flex items-center justify-center font-extrabold uppercase cursor-pointer'>

//          <div   className='pointer-events-none flex items-center justify-center'>
//             <img className='mr-2 pointer-events-none' src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="lang" /> 
//             <span className='pointer-events-none'>{item.text}</span>
//          </div>

//       </Link> ))
//          }

   return (
   <>
   {/* step1.buttons.button */}
   
{
            (loadStep1)&&

            <section className={steptwo?'w-full h-full  fixed top-0 left-0 bg-bgColor  flex items-center justify-center localSection opacity-0':' w-full  flex items-center justify-center localSection'}>
               <div className=' sm:w-auto w-full sm:px-0 px-2.5 mb-20'>
            
               <div className='bg-bgColor-light border border-bgColor  flex flex-col items-center justify-center localContainer'>
                  <h2 className='font-semibold sm:text-xl text-lg pb-8' >{step1.title}</h2>
                  <div className={step1Btn.length>1?'flex items-center justify-start sm:flex-row flex-wrap step1Btns w-full':'flex items-center justify-center sm:flex-row flex-wrap step1Btns w-full'}>
                  <Link  className='h-0 w-0 overflow-hidden absolute top-0 left-0 -z-50 opacity-0' ref={link} to='/step_two'></Link>
                  {step1Btn&&
                  
                  step1Btn.map((item:any,index:any)=>(
                     <div  key={index} className='sm:w-1/2 w-full p-1'>
                        <Link  to='/step_two' onClick={(e)=>toggleLang(e)} id={item.name}  className='  hover:bg-green duration-300  localBtn py-3   border-green flex items-center justify-center font-extrabold uppercase cursor-pointer'>
                           
                           <div   className={step1Btn.length>1?'pointer-events-none flex items-center justify-start w-40 pl-3':'pointer-events-none flex items-center justify-center'}>
                              <img className='mr-2 pointer-events-none' src={item.image} alt="lang" /> 
                              <span className='pointer-events-none'>{item.name}</span>
                           </div>
                  
                        </Link>
                     </div>
                      ))
                        }
                  </div>
                  
               </div>
               </div>

            
            </section>
         
         } 
   </>
   )
}

export default StepOne