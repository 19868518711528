import React,{useState,useEffect, useCallback, useRef} from 'react'
import { useLoadingContext } from "react-router-loading";
import Config from './Config'
import Promocode from './Promocode'
import './styles/styles.css'
import { toPng } from 'html-to-image';
import ImageUploading from 'react-images-uploading';
import {  addPromoStyles, actualPromoStyles, addBeforeEl,addDefImg,incrementDefImg } from '../features/counter/counterSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import DefaultImages from './DefaultImages';
//http://94.198.54.128:3004/ - фронт



const Main = () => {
   const loadingContext = useLoadingContext();
   const dispatch=useAppDispatch()
   const styles = useAppSelector(actualPromoStyles)
   // let promoPosition=[0, 0]
   // let x = 0 
   // let y = 0
   // const getCoord = (x:any,y:any)=>{
   //    let promo = document.querySelector('.promo')
   //    let coord = promo?.getBoundingClientRect()
   //    x =coord?.x
   //    y =coord?.y
   //    console.log('coord?.x',coord?.x)
   // }
   // const [, drop] = useDrop(
   //    () => ({
   //       accept: ItemTypes.PROMO,
   //       drop: () => getCoord(x,y),
   //       collect: (monitor) => ({
   //          isOver: !!monitor.isOver()
   //       })
   //       }),
   //       [x,y]
   //  )

   const [images, setImages] = useState([]);
   const maxNumber = 1;
   const [loaded,setLoaded] = useState(false)
   //const [userImage,setUserImage] = useState('')
   const [settings,setSettings] = useState<any>()
   const [imgS,setImgS] = useState('')
   const isClicked = useRef<boolean>(false)
   const isClickedP = useRef<boolean>(false)
   const isClickedTR = useRef<boolean>(false)
   const isClickedBL = useRef<boolean>(false)
   const isClickedRotate = useRef<boolean>(false)
   const container = useRef<HTMLDivElement>(null)
   const code = useRef<HTMLDivElement>(null)
   const configRef = useRef<HTMLDivElement>(null)
   const rotateRef = useRef<any>(null)
   const [defaultImg,setDefaultImg]=useState(true)
   const [btnH,setBtnH]=useState('')

   useEffect(()=>{
      loadingContext.done();
      fetch(`https://promocodes.themultiform.com/app/api/setting?populate[background][populate][setting][fields]&populate[textSettings][populate][setting][fields]`)
      .then((res)=>res.json())
      .then((result)=>{
         console.log(result.data.attributes)
         setSettings(result.data.attributes)
      })
      // .then(()=>{
      //    fetch(`https://promocodes.themultiform.com/app/api/user-image?populate[image][fields]=url`)
      //    .then((res)=>res.json())
      //    .then((result)=>{
      //       setUserImage(`http://94.198.54.128:1340${result.data.attributes.image.data.attributes.url}`)
      //    })
      // })
      
      .then(()=>{
         setLoaded(true)
      })
      .catch((err)=>{console.log(err)})
   },[])


   // funcs to move promocode 
   const onMouseDown = (e:any) =>{
      
      isClicked.current = true

      coords.current.startX = e.clientX?e.clientX:e.touches[0].clientX
      coords.current.startY = e.clientY?e.clientY:e.touches[0].clientY
      //console.log('mD')


      if(e.touches||e.touches){
         document.body.classList.add('overflow-hidden')
      }
   }
   const onMouseUp = (e:any) =>{
      
      isClickedP.current=false
      isClickedTR.current = false
      isClickedBL.current = false
      isClickedRotate.current = false
      isClicked.current = false
      if(code.current){
         coords.current.lastX = code.current.offsetLeft
         coords.current.lastY = code.current.offsetTop
      }
      //console.log('mU')

         document.body.classList.remove('overflow-hidden')
      
   }

   
   const onMouseMove = (e:any) =>{
      
      
      if(!isClicked.current || isClickedP.current ) return


      let w = code.current&&container.current&&container.current.clientWidth - code.current.clientWidth
      let h = code.current&&container.current&&container.current.clientHeight - code.current.clientHeight
      const nextX = (e.clientX?e.clientX:e.touches[0].clientX) - coords.current.startX  + coords.current.lastX
      const nextY = (e.clientY?e.clientY:e.touches[0].clientY) - coords.current.startY + coords.current.lastY
      //console.log(nextY,coords.current.lastX)
      if(!isClickedRotate.current&&h&&w&&nextX>=0&&nextY>=0&&nextX<=w&&nextY<=h){
         //let olds = code.current&&code.current.getAttribute('style')
         code.current&&code.current.setAttribute('style',`transform:rotate(${styles.find((item:any)=>item.style.name==="transform")?.style.value}deg);top:${nextY}px;left:${nextX}px`)
      }
      if(isClickedTR.current){
         code.current&&code.current.setAttribute('style',`transform:rotate(${styles.find((item:any)=>item.style.name==="transform")?.style.value}deg);top:${nextY}px;left:${coords.current.lastX}px`)
      }
      if(isClickedBL.current){
         
         code.current&&code.current.setAttribute('style',`transform:rotate(${styles.find((item:any)=>item.style.name==="transform")?.style.value}deg);top:${coords.current.lastY}px;left:${nextX}px`)
      }

      if(isClickedRotate.current){
         

      
         let promocode = document.querySelector('.promocode')
         let arrowRects = promocode?.getBoundingClientRect()
         let arrowX = arrowRects&&arrowRects.left + arrowRects.width / 2
         let arrowY = arrowRects&&arrowRects.top + arrowRects.height / 2
         console.log(arrowY)
         let angle = arrowX&&arrowY&&Math.atan2((e.clientY?e.clientY:e.touches[0].clientY) - arrowY, (e.clientX?e.clientX:e.touches[0].clientX) - arrowX) + Math.PI / 2
         dispatch(addPromoStyles(
            {
               style:{
                  id:rotateRef.current.id,
                  name:rotateRef.current.dataset.style,
                  value:`${angle&&Math.ceil((angle * 180) / Math.PI)}`,
                  units:rotateRef.current.dataset.units
               }
            }
      ))

         angle&&code.current&&code.current.setAttribute('style',`transform:rotate(${styles.find((item:any)=>item.style.name==="transform")?.style.value}deg);top:${coords.current.lastY}px;left:${coords.current.lastX}px`)
      
         rotateRef.current.value = angle&&Math.ceil((angle * 180) / Math.PI)
         
         console.log(arrowX&&arrowY&&Math.atan2((e.clientY?e.clientY:e.touches[0].clientY) - arrowY, (e.clientX?e.clientX:e.touches[0].clientX) - arrowX) + Math.PI / 2)
      }
      
   }


   const coords = useRef<{
      startX:number
      startY:number
      lastX:number
      lastY:number
   }>({
      startX:0,
      startY:0,
      lastX:0,
      lastY:0
   })

   //console.log(isClicked,isClickedP,isClickedTR,isClickedBL,isClickedRotate)
   useEffect(()=>{
      if(loaded){
         //console.log(configRef.current?.clientHeight)
         // 

         setBtnH(`${configRef.current?.clientHeight}px`)
      }
      
   },[loaded])


   //Saving result

   const myRes = useRef<HTMLDivElement>(null)
   const onButtonClick = useCallback(() => {
      if (myRes.current === null) {
         console.log('null')
         return
         }
   
         dispatch(addBeforeEl(false))
         toPng(myRes.current, { cacheBust: true, })
         .then((dataUrl:string) => {
            const link = document.createElement('a')
            link.download = 'yourPromo.png'
            link.href = dataUrl
            link.click()
         })
         .then(()=>{
            dispatch(addBeforeEl(true))
         })
         .catch((err:any) => {
            console.log(err)
         })
      }, [myRes])

   //upload image

   const onChangeImg = (imageList:any, addUpdateIndex:any) => {
      // data for submit
      //console.log(imageList, addUpdateIndex);
      setImages(imageList);
   };


   // choose Image
   const defaultImgChange = () => {
   dispatch(incrementDefImg())
   }
   //Mobile settings
   const[settingsActive,setSettingsActive]=useState(true)
   const setClick = () => {
      setSettingsActive(prev=>!prev)
      console.log(configRef.current?.clientWidth)
   }
   return (
      <div  className=' h-full mainBlock sm:p-16 p-4'>
         <div className=' text-white  font-bold text-center sm:pb-16 sm:pt-0 pb-8 pt-4 flex items-center justify-center'>
               <button onClick={()=>{defaultImg?defaultImgChange():setDefaultImg(true)}} className=' bg-blue-light py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200 mr-1 '>Выбери картинку</button>
               <button onClick={()=>{setDefaultImg(false)}} className=' bg-blue-light py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200 ml-1 '>Или добавь свою</button>
         </div>
         <div className='text-white font-bold pb-4  justify-start settingBtn'>
            <button onClick={setClick} className=' bg-grey-dark py-2 px-4 rounded  hover:shadow-md hover:shadow-grey duration-200 w-full'>{!settingsActive?'Настройки': 'Cкрыть Настройки'}</button>

         </div>
         <div  className='flex items-start justify-end  h-fit relative'>
            
            {
               loaded&&
               <>
               
               <div ref={configRef} className={!settingsActive?'  overflow-hidden duration-300 absolute z-30 top-0 -left-96  opacity-0':'rounded shadow-md shadow-blue-solight absolute opacity-1 z-30 top-0 left-0   overflow-hidden duration-300'}>
                  <Config rotateRef={rotateRef} onButtonClick={onButtonClick} settings={settings}/>
               </div>
                  
                     


            
               {defaultImg?
               <div ref={myRes} className={settingsActive?'sm:w-2/3 w-full imgCntnr  flex items-center justify-center duration-300':' duration-300 w-full imgCntnr  flex items-center justify-center'} >
                  <DefaultImages onMouseMove={onMouseMove}  onMouseLeave={onMouseUp} container={container} rotateRef={rotateRef} isClickedRotate={isClickedRotate} isClickedBL={isClickedBL} isClickedTR={isClickedTR} isClickedP={isClickedP} code={code} onMouseDown={onMouseDown} onMouseUp={onMouseUp}/>

               </div>
               
               :<ImageUploading
                     multiple
                     value={images}
                     onChange={onChangeImg}
                     maxNumber={maxNumber}
                     dataURLKey="data_url"
                     >
                     {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                     }) => (
                        // write your building UI
                        <div  className="upload__image-wrapper text-white  sm:w-2/3 w-full">
                           <button style={{minHeight:btnH}} className={imageList.length?'hidden':'border border-dotted w-full myHeight text-white hover:text-blue-solight  py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight hover:border-blue duration-200 font-bold'}
                           
                           onClick={onImageUpload}
                           {...dragProps}
                           >
                           Click or Drop here
                           </button>
                           
                           {/* <button className='bg-blue-light ml-1 py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200 font-bold' onClick={onImageRemoveAll}>Remove all images</button> */}
                           {imageList.map((image, index) => (
                           <div key={index} className="image-item flex justify-center flex-col items-center h-full ">
                              
                              <div  ref={myRes} className='w-fit imgCntnr'>
                                 <div onTouchMove={onMouseMove} onMouseMove={onMouseMove} onTouchEnd={onMouseUp} onMouseUp={onMouseUp} onMouseLeave={onMouseUp} ref={container} className='relative overflow-hidden '>
                                    <img src={image['data_url']} alt="userImg" className='w-full' />
                                    <Promocode rotateRef={rotateRef} isClickedRotate={isClickedRotate} isClickedBL={isClickedBL} isClickedTR={isClickedTR} isClickedP={isClickedP} code={code} onMouseDown={onMouseDown} onMouseUp={onMouseUp}/>

                                    
                                 </div>
                              </div>
                              <div className="image-item__btn-wrapper">
                                 <button className='bg-blue-light py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200 font-bold mr-1' onClick={() => onImageUpdate(index)}>Update</button>
                                 <button className='bg-blue-light py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200 font-bold ml-1' onClick={() => onImageRemove(index)}>Remove</button>
                              </div>
                           </div>
                           ))}
                        </div>
                     )}
               </ImageUploading>}
               
               
               </>
            }

         </div>
            {
               loaded&&
               <div className=' text-white  font-bold text-center btnSend'>
                  <button onClick={onButtonClick} className='bg-blue-light py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200'>{settings.button}</button>
               </div>
            }
         
      </div>

   )
}

export default Main