import React,{FC,useEffect,useState} from 'react'
import {    addDefImg,actualDefImg } from '../features/counter/counterSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import Promocode from './Promocode'

const DefaultImages:FC<any>  = ({onMouseMove, onMouseLeave, container,rotateRef,isClickedRotate, isClickedBL, isClickedTR, isClickedP, code, onMouseDown, onMouseUp,}) => {
   const dispatch = useAppDispatch()
   const defImg = useAppSelector(actualDefImg)

   const[loaded,setLoaded]=useState(false)
   const[imgs,setImgs]=useState([])
   useEffect(()=>{
      fetch(`https://promocodes.themultiform.com/app/api/default-image?populate[images][fields]=url`)
      .then((res)=>res.json())
      .then((result)=>{
         setImgs(result.data.attributes.images.data)
      })
      .then(()=>{
         setLoaded(true)
      })
      .catch((err)=>{
         console.log(err)
      })
   },[])
   
   useEffect(()=>{
      console.log(imgs.length)
      if(defImg >= imgs.length){
         dispatch(addDefImg(0))
      }
   },[defImg])
  return (
    <div className='text-white '>
      {loaded&&
      <div  onMouseMove={onMouseMove} onTouchMove={onMouseMove} onTouchEnd={onMouseUp} onMouseUp={onMouseUp} onMouseLeave={onMouseUp} ref={container} className='relative overflow-hidden '>
         {
            imgs.map((item:any,index:any)=>(
               <div key={item.id}  className={index!==defImg ?'hidden':'h-fit'}>
                  <img style={{maxHeight:'800px'}} src={`https://promocodes.themultiform.com/app/${item.attributes.url}`} alt="img" />
               </div>
            ))
         }
            <Promocode rotateRef={rotateRef} isClickedRotate={isClickedRotate} isClickedBL={isClickedBL} isClickedTR={isClickedTR} isClickedP={isClickedP} code={code} onMouseDown={onMouseDown} onMouseUp={onMouseUp}/>

      </div>
      }
    </div>
  )
}

export default DefaultImages