
import React,{FC,useEffect, useRef, useState} from 'react'
import { useAppSelector,useAppDispatch } from '../app/hooks'
import  { actualPromoStyles,actualPromoStylesText ,actualBeforeEl} from '../features/counter/counterSlice'

import { Resizable } from 're-resizable'
export  const ItemTypes = {
  PROMO: 'promo'
}

type AnyT={
  onMouseDown:any
  onMouseUp:any
  code:any
  isClickedP:boolean
  isClickedTR:boolean
  isClickedBL:boolean
  isClickedRotate:boolean
  rotateRef:any
  
}
const Promocode:FC<any> = ({rotateRef,onMouseDown,code,onMouseUp,isClickedP,isClickedBL,isClickedTR,isClickedRotate}) => {
  //const dispatch = useAppDispatch()
  const beforeEl = useAppSelector(actualBeforeEl)
  const styles = useAppSelector(actualPromoStyles)
  const stylesText = useAppSelector(actualPromoStylesText)
  const [bgs,setBgs] = useState(styles)
  const [tgs,setTgs] = useState(stylesText)
  
  




  useEffect(()=>{

    setBgs(styles)
  },[styles])
  useEffect(()=>{

    setTgs(stylesText)
  },[stylesText])

  useEffect(()=>{
    //console.log(bgs)
    let bg= document.querySelector('.promocode')
    let els = document.querySelectorAll('.rotaitngElRes')
    //console.log(bg)
    if(bgs.length)
      els.forEach(el=>{
        el.setAttribute('style',`${el.getAttribute('style')};transform:rotate(${bgs.find((item:any)=>item.style.name==="transform")?.style.value}deg)`)
      })
      code.current&&code.current.setAttribute('style',`transform:rotate(${bgs.find((item:any)=>item.style.name==="transform")?.style.value}deg);top:${code.current.style.top};left:${code.current.style.left}`)
      bg?.setAttribute('style',`${bgs.map((item:any)=>(
        
        item.style.name!=='transform'&&
        // `${item.style.name}:rotate(${item.style.value}${item.style.units?item.style.units:''})`
        // :
        (item.style.name==='box-shadow'?
        `${item.style.name}:0 0 5px 2px ${item.style.value}${item.style.units?item.style.units:''}`
        :
        `${item.style.name}:${item.style.value}${item.style.units?item.style.units:''}`)

      )).join(';')}`)


  },[bgs])
  useEffect(()=>{
    //console.log(tgs)
    let tg= document.querySelector('.promocode-text')
    //console.log(tg)
    if(tgs.length)
      tg?.setAttribute('style',`${tgs.map((item:any)=>(
        item.style.name!=='none'?
        `${item.style.name}:${item.style.value}${item.style.units?item.style.units:''}`
        :''

      )).join(';')}`)


  },[tgs])

  // const paddingRefL = useRef<HTMLDivElement>(null)
  // const paddingRefR = useRef<HTMLDivElement>(null)
  //const [evNone,setEvNone]=useState(false)
  
  // const resFunc = () =>{
  //   setEvNone(true)
  // }
  

  useEffect(()=>{

    let resizeBR=document.querySelector('.resizeElBR')
    let resizeBL=document.querySelector('.resizeElBL')
    let resizeTR=document.querySelector('.resizeElTR')
    let promoRotate = document.querySelector('.promo__rotate')
    const onMouseDownBR=()=>{
      isClickedP.current=true
      
    }
    const onMouseDownRtt=(e:any)=>{
      isClickedRotate.current=true
      
    }
    const onMouseDownBL=()=>{
      isClickedBL.current=true
      
    }
    const onMouseDownTR=()=>{
      isClickedTR.current=true
      
    }
    resizeBR&&resizeBR.addEventListener('mousedown',onMouseDownBR)
    resizeBL&&resizeBL.addEventListener('mousedown',onMouseDownBL)
    resizeTR&&resizeTR.addEventListener('mousedown',onMouseDownTR)
    promoRotate&&promoRotate.addEventListener('mousedown',onMouseDownRtt)
    resizeBR&&resizeBR.addEventListener('touchstart',onMouseDownBR)
    resizeBL&&resizeBL.addEventListener('touchstart',onMouseDownBL)
    resizeTR&&resizeTR.addEventListener('touchstart',onMouseDownTR)
    promoRotate&&promoRotate.addEventListener('touchstart',onMouseDownRtt)
    const cleanup=()=>{
      resizeBR&&resizeBR.removeEventListener('mousedown',onMouseDownBR)
      resizeBL&&resizeBL.addEventListener('mousedown',onMouseDownBL)
      resizeTR&&resizeTR.removeEventListener('mousedown',onMouseDownTR)  
      promoRotate&&promoRotate.removeEventListener('mousedown',onMouseDownRtt) 
      resizeBR&&resizeBR.removeEventListener('touchstart',onMouseDownBR)
      resizeBL&&resizeBL.addEventListener('touchstart',onMouseDownBL)
      resizeTR&&resizeTR.removeEventListener('touchstart',onMouseDownTR)  
      promoRotate&&promoRotate.removeEventListener('touchstart',onMouseDownRtt) 
    }

    return cleanup
 },[])

 console.log(code.current?.clientHeight)
  return (
    
      <div ref={code} onTouchStart={onMouseDown} onMouseDown={onMouseDown} onTouchEnd={onMouseUp} onMouseUp={onMouseUp} className={' code-wrapper pointer-events-auto cursor-pointer promo absolute z-10 w-fit h-fit'} style={{top:'0',left:'0', }}> 
      <Resizable className=''
                  defaultSize={{
                    width:'auto',
                    height:'auto',
                  }}
                  minHeight={bgs&&bgs.find((item:any)=>item.style.name==="padding")?Number(bgs.find((item:any)=>item.style.name==="padding")?.style.value)*2+'px':'0'}
                  minWidth={bgs&&bgs.find((item:any)=>item.style.name==="padding")?Number(bgs.find((item:any)=>item.style.name==="padding")?.style.value)*2+'px':'0'}
                  boundsByDirection={true}
                  handleClasses={{bottomRight:'resizeElBR rotaitngElRes',topRight:'resizeElTR rotaitngElRes',topLeft:' rotaitngElRes',bottomLeft:'resizeElBL rotaitngElRes'}}
                  enable={{top:false, right:false, bottom:false, left:false, topRight:true, bottomRight:true, bottomLeft:true, topLeft:true}}
                  >
      
        <div  className='promocode relative w-full h-full flex items-center justify-center' >
          <b className={beforeEl?'promo__rotate':'hidden'}>
              
              {/* <div className='w-6 h-6 rounded-full   bg-blue-light hover:bg-red'>
              
              </div> */}
              
            </b>
          
          <div className='promocode-text leading-none'>
            {tgs.find((item:any)=>item.style.name==='none')?.style.value}
          </div>
        </div>
        </Resizable>
      </div>
                                       
      
    
    
    
    
  )
}

export default Promocode