import React,{FC,useEffect,useState,useRef} from 'react'
import { useLoadingContext } from "react-router-loading";
import { Link } from "react-router-dom";
import { actualLoadedL,addLoadedL,addLoaded,addLoad3,actualLang,actualCode,addCode,addDefLang,actualDefLang } from '../features/counter/counterSlice';
import { useAppDispatch,useAppSelector } from '../app/hooks';
const StepTwo:FC<any> = ({updateURL,header}) => {
   const mycode=useAppSelector(actualCode)
   const dispatch = useAppDispatch()
   const loadingContext = useLoadingContext();
   let localLang:string = window.navigator.language
   const lang = useAppSelector(actualLang)
   // useEffect(()=>{
   //    if(!localStorage.getItem('step')){
   //       localStorage.setItem('step', '2')
   //    }
      
   // },[])
   const loadedL = useAppSelector(actualLoadedL)
   const defLang=useAppSelector(actualDefLang)
   const[step2,setStep2]=useState<any>()
   const [loadStep2,setLoadStep2]=useState(false)
   //const[header,setHeader]=useState<any>()
   //promocode func 
   const[codeVal,setCodeVal]=useState('')
   const[codeValue,setCodeValue]=useState('')

   const[ready,setReady]=useState(true)
   const funcT = (e:any)=>{
      const re = /^[a-zA-Z0-9]+$/;
      let wls:any=window
      .location
      .search
      .replace('?lang=','').replace('code=','').split('&')
      let ifl=wls[0]
      let l =wls[0].length&&ifl
      if(re.test(e.target.value)||e.target.value===''){
         let val = e.target.value
         if(e.target.value.length>10){
            val = e.target.value.slice(0, -1);
            alert(`${lang.includes('en')?'Maximum promo code size is 10 characters':'Максимальный размер промокода 10 символов'}`)
         }
         setCodeVal(val)
         setReady(true)
         dispatch(addCode(val))

        
         
         
         updateURL(localStorage.getItem('lang')?localStorage.getItem('lang'):l, val)
      }else{
         alert(`${lang.includes('en')?'Use English language and numbers to enter the promo code':'Используйте английский язык и цифры для ввода промокода'}`)
      }
   }
   // useEffect(()=>{
   //    if(loadStep2){
   //       //updateURL()
   //       let wls:any=window
   //       .location
   //       .search
   //       .replace('?lang=','').split('&')
   //       console.log(wls)
   //       //dispatch(addLang(wls[0].length?wls[0]:localLang.includes('RUSSIAN')?'RUSSIAN':'ENGLISH'))

   //    }
   // },[loadStep2,codeVal])
   const loading = async () => {
      // loading some data
      dispatch(addLoadedL(true))
      console.log('scroll2')
      window.scrollTo(0,0)
      // call method to indicate that loading is done and we are ready to switch
      loadingContext.done();
   };
   useEffect(()=>{
      dispatch(addLoadedL(false))
   },[])

   useEffect(()=>{
      

      let wls:any=window
      .location
      .search
      .replace('?lang=','').replace('code=','').split('&')
      let ifc = wls[1]?wls[1]:mycode
      
         let ifl=wls[0]
         let l =(wls[0].length&&ifl!=='null')?ifl:defLang
      updateURL(localStorage.getItem('lang')?localStorage.getItem('lang'):l,ifc)
      

            fetch(`https://promocodes.themultiform.com/app/api/enter-promocode?populate[input][fields]&populate[button][populate][img][fields]=url&locale=${lang}`)
            .then((res)=>res.json())
            .then((result)=>{
               //console.log(result.data.attributes)
               setStep2(result.data.attributes)
               
               setCodeValue(result.data.attributes.input.text)
              

                  
                  if(ifc){
                     setCodeVal(decodeURIComponent(ifc))
                     setReady(true)
                  }else{
                     setReady(false)
                     setCodeVal('')
                  }
               
            })
            .then(()=>{
               setLoadStep2(true)
               
            })
            .then(()=>{
               dispatch(addLoad3(false))
               loading()
               dispatch(addLoaded(true))
               
            })
         
   
         .catch((err)=>{console.log(err)})
   
      
      
      
   
   },[lang,localLang])
   const link =useRef<any>(null)
  const[go,setGo]=useState(false)
  
  const goToPromoKey =(event:any) =>{
   
   if(event.key==='Enter'){
      let wls:any=window
      .location
      .search
      .replace('?lang=','').replace('code=','').split('&')
      let ifl=wls[0]
      let l =(wls[0].length&&ifl!=='null')?ifl:defLang

      if(codeVal.length&&ready){
         setGo(true)
        // setLoad2(false)
        link.current&&link.current.click()
        
        //updateURL()
        // updateURL(localStorage.getItem('lang')?.includes('RUSSIAN')?'RUSSIAN':'ENGLISH', codeVal)
       
         
         updateURL(localStorage.getItem('lang')?localStorage.getItem('lang'):l, codeVal)
         
         //localStorage.setItem('lang',l)

        dispatch(addCode(codeVal))
     }else{
        alert(`${lang.includes('en')?'Enter promocode':'Введите промокод'}`)
     }
   }
   
   


}

   const goToPromo =() =>{
      let wls:any=window
         .location
         .search
         .replace('?lang=','').replace('code=','').split('&')
         let ifl=wls[0]
         let l =(wls[0].length&&ifl!=='null')?ifl:defLang
         


      if(codeVal.length&&ready){
          setGo(true)
         // setLoad2(false)
         link.current&&link.current.click()
         // console.log('gotopromo',l)
         updateURL(localStorage.getItem('lang')?localStorage.getItem('lang'):l, codeVal)
         //updateURL()
         // updateURL(localStorage.getItem('lang')?.includes('RUSSIAN')?'RUSSIAN':'ENGLISH', codeVal)
         
         //localStorage.setItem('lang',l)

         dispatch(addCode(codeVal))
      }else{
         alert(`${lang.includes('en')?'Enter promocode':'Введите промокод'}`)
      }
      
   
   
   }

const myInput = useRef<HTMLInputElement>(null)

   //updateURL
// function updateURL() {
//    if (window.history.replaceState) {
//       var baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
//       var newUrl = baseUrl + `?lang=${localStorage.getItem('lang')}`;
//       window.history.replaceState(null,'', newUrl);
//    }
//    else {
//       console.warn('History API не поддерживается');
//    }
// }
// function updateURLPromo() {
//    let wls:any=window
//          .location
//          .search
//          .replace('?lang=','').replace('code=','').split('&')
//    console.log(wls)
//    if (window.history.replaceState) {
//       let l = wls[0].length&&wls[0].includes('RUSSIAN')?'RUSSIAN':'ENGLISH'
//       var baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.search;
//       var newUrl = baseUrl + `?lang=${l?l:localLang.includes('RUSSIAN')?'RUSSIAN':'ENGLISH'}`;
//       window.history.replaceState(null,'', newUrl);
//       if(!localStorage.getItem('lang'))
//       localStorage.setItem('lang',l)
//    }
//       else {
//          console.warn('History API не поддерживается');
//       }
//    }

useEffect(()=>{
   if(loadStep2){
      

      
      setTimeout(() => {
         if(myInput.current===null)
            return

         myInput.current.focus()
         myInput.current.setAttribute('autofocus','true')
      }, 100);
      
   }
},[loadStep2])
const remLoc =() =>{
   localStorage.removeItem('step')
}
   return (
   <>
            {
            (loadStep2)&&
            <section className='w-full h-full z-30 flex flex-col items-center justify-center promoSection'>
               <div className=' sm:w-auto w-full sm:px-0 px-2.5 h-max mb-20'>
               <div className='pb-2.5 '>
                     <Link to='/' onClick={remLoc} className='flex items-center justify-start w-fit uppercase font-bold'>
                        <img className='mr-1' src={`https://promocodes.themultiform.com/app${header.back.img.data.attributes.url}`} alt="back" />
                        <span>{header.back.text}</span>
                     </Link>
                  </div>
               <div className='bg-bgColor-light border border-bgColor flex flex-col items-center justify-center promoContainer px-4'>
                  <h2 className='font-semibold text-xl sm:pb-8 pb-5 px-2.5' >{step2.title}</h2>
                  <div className={loadedL?'flex flex-col items-start justify-center promoContainer__div':'pointer-events-none flex flex-col items-start justify-center promoContainer__div'}>
                     <label className='font-semibold  pb-2.5 ' htmlFor="">{step2.input.label}</label>
                     <input  autoFocus ref={myInput} onKeyDown={goToPromoKey} className='w-full px-3 py-2.5 font-semibold  bg-grey-dark text-white  promoInput' type="text" onChange={(e)=>funcT(e)}  placeholder={codeValue} value={codeVal}/>
               
                     <div className='text-white uppercase font-semibold text-center pt-5 w-full'  >
                     <button onClick={goToPromo}  className='w-full py-2 -mt-2'>
                        <div className='bg-green   btnPromo2 hove}r:shadow-md hover:shadow-blue-solight duration-200 cursor-pointer'>
                           <Link to={"/step_three"} ref={link} className={'pointer-events-none flex items-center justify-center'}>
                                 <div>
                                    <div className='bg-green-dark h-10 w-10 flex items-center justify-center btnPromoImg2'>
                                       <img className='w-4 h-4' src={`https://promocodes.themultiform.com/app${step2.button.img.data.attributes.url}`} alt="btn" />
                                    </div>
                                 </div>
                                 
                                 <div className='text-sm2 leading-4 pr-2 pl-2 sm:pl-4 sm:pr-5 font-extrabold w-full'>
                                    <span>{step2.button.text}</span>
                                 </div>
                              
                           </Link>
                        </div>
                        
                        </button>
                     </div>
                  </div>

               
               </div>
               </div>

            
            </section>
         
         } 
   </>
   )
}

export default StepTwo