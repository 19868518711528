import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Route, Routes, useLoadingContext } from 'react-router-loading'

import { topbar } from 'react-router-loading'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import {
  actualLang,
  actualLoad3,
  actualLoaded,
  actualLoadedL,
  actualWlsLang,
  addDefLang,
  addLang,
  addLoad3,
  addLoaded,
} from '../features/counter/counterSlice'
import StepOne from './StepOne'
import StepThree from './StepThree'
import StepTwo from './StepTwo'
import './styles/style.css'

const places = [
  {
    id: '1',
    place1: 'top-16',
    place2: 'left-4',
  },
  {
    id: '2',
    place1: 'bottom-8',
    place2: 'left-4',
  },
  {
    id: '3',
    place1: 'bottom-1/3',
    place2: 'right-4',
  },
]
// const imgs = [
//    {
//       id:'1',
//       url:img1
//    },{
//       id:'2',
//       url:img2
//    },
//    {
//       id:'3',
//       url:img3
//    },
//    {
//       id:'4',
//       url:img1
//    },
//    {
//       id:'5',
//       url:img2
//    }
// ]

const MainPromo = () => {
  //fetching
  topbar.config({
    autoRun: true,
    barThickness: 5,
    barColors: {
      0: '#6B6E7F',
      0.3: '#49BEE1',
      1.0: '#49E146',
    },
    shadowBlur: 5,
    shadowColor: 'green',
    className: 'topbar',
  })

  const loadedL = useAppSelector(actualLoadedL)

  const wlsLang = useAppSelector(actualWlsLang)
  const [step1Btn, setStep1Btn] = useState<any>()
  const dispatch = useAppDispatch()
  const load3 = useAppSelector(actualLoad3)
  const lang = useAppSelector(actualLang)
  const loaded = useAppSelector(actualLoaded)
  const loadingContext = useLoadingContext()
  // useEffect(()=>{
  //    loadingContext.done()
  // },[])

  //promocode func
  const [codeVal, setCodeVal] = useState('')
  const [ready, setReady] = useState(false)
  const funcT = (e: any) => {
    setCodeVal(e.target.value)
    setReady(true)
  }
  // useEffect(()=>{

  //    if(load2){
  //       localStorage.removeItem('promocode')
  //       //updateURL()
  //       if(ready){
  //          if(codeVal.length){
  //             console.log(codeVal)
  //          }else{
  //             setReady(false)
  //          }
  //       }
  //    }
  // },[codeVal])
  //promocode place
  // const[place,setPlace]=useState(0)
  // const btnPlace = () => {
  //    if(place===places.length-1){
  //       setPlace(0)
  //    }else{
  //       setPlace(prev=>prev+1)
  //    }

  // }
  //Saving result

  // const myRes = useRef<HTMLDivElement>(null)
  // const myResSm = useRef<HTMLDivElement>(null)
  // const [imgRes, setImgRes] = useState<any>()
  // const [btnFlag, setIBtnFlag] = useState(false)

  // const onButtonClick = useCallback((e:any) => {
  //    console.log(myRes)
  //    setImgRes(e.target.id)

  //    if(!btnFlag){
  //       setIBtnFlag(true)
  //       setTimeout(() => {

  //          if (myRes.current === null) {
  //             console.log('null')
  //             return
  //          }

  //          toPng(myRes.current, { cacheBust: true, })
  //          .then((dataUrl:string) => {
  //             const link = document.createElement('a')
  //             link.download = 'yourPromo.png'
  //             link.href = dataUrl
  //             link.click()
  //          })
  //          .then(()=>{
  //             setIBtnFlag(false)
  //          })
  //          .catch((err:any) => {
  //             console.log(err)
  //          })
  //       }, 200);
  //    }

  // }, [myRes])
  // const onButtonClickSm = useCallback((e:any) => {
  //    console.log(myResSm)
  //    setImgRes(e.target.id)

  //    if(!btnFlag){
  //       setIBtnFlag(true)
  //       setTimeout(() => {

  //          if (myResSm.current === null) {
  //             console.log('null')
  //             return
  //          }

  //          toPng(myResSm.current, { cacheBust: true, })
  //          .then((dataUrl:string) => {
  //             const link = document.createElement('a')
  //             link.download = 'yourPromo.png'
  //             link.href = dataUrl
  //             link.click()
  //          })
  //          .then(()=>{
  //             setIBtnFlag(false)
  //          })
  //          .catch((err:any) => {
  //             console.log(err)
  //          })
  //       }, 200);
  //    }

  // }, [myRes])

  //Saving All

  // const onSaveAllClick = useCallback((e:any) => {
  //    let resultImg = document.querySelectorAll('.resultImg')
  //    if (!resultImg) {
  //       console.log('null')
  //       return
  //    }
  //    resultImg.forEach((el:any) => {
  //       toPng(el, { cacheBust: true, })

  //       .then((dataUrl:string) => {
  //          const link = document.createElement('a')
  //          link.download = 'yourPromo.png'
  //          link.href = dataUrl
  //          setFiles(link)
  //          //console.log(link)
  //          //link.click()
  //       })
  //       .catch((err:any) => {
  //          console.log(err)
  //       })
  //    });

  // }, [])

  //zipping
  // const[filesReady,setFilesReady]=useState(false)
  // const[files,setFiles]=useState<any>([])
  // const imgRef = useRef<HTMLImageElement>(null)
  // const downloadZip = () => {
  //    let resultImg = document.querySelectorAll('.resultImg')
  //    if(resultImg){
  //       resultImg.forEach((el:any,index:any) => {
  //          toPng(el, { cacheBust: true, })

  //          .then((dataUrl:string) => {
  //             const link = document.createElement('a')
  //             link.download = `yourPromo_${index+1}.png`
  //             link.href = dataUrl
  //             //console.log(link.href)
  //             //link.click()
  //             //generateZip(link)
  //             setFiles((prev:any)=>[
  //                ...prev,link
  //             ])
  //          })
  //          .catch((err:any) => {
  //             console.log(err)
  //          })
  //       });

  //     generateZip(imgRef?.current);
  //     console.log(imgRef?.current)
  //     }

  //  };
  //  useEffect(()=>{
  //     console.log(files)

  //     if(files.length===document.querySelectorAll('.resultImg').length)
  //     generateZip(files)
  //    //  onChangeFile()
  //    //  downloadZip()
  //  },[files])

  //const onChangeFile = () => {

  //    if(imgRef.current){
  //    const zip = require('jszip')();
  //     let files = imgRef?.current.src;
  //     zip.file('lmlkmkml', files);
  //    //  for (let file = 0; file < e.target.files.length; file++) {
  //    //    // Архивировать файл с именем файла.
  //    //    zip.file(files[file].name, files[file]);
  //    //  }
  //     zip.generateAsync({type: "blob"}).then((content:any) => {
  //       saveAs(content, "example.zip");
  //     }); }
  //   }

  // const [loadStep1,setLoadStep1]=useState(false)
  // const [loadStep2,setLoadStep2]=useState(false)
  // const [loadStep3,setLoadStep3]=useState(false)
  let localLang: string = window.navigator.language
  //let state={}
  //let [stLang,setState]=useState('')
  //state.lang=stLang

  // if(!localStorage.getItem('lang')){
  //    localStorage.setItem('lang', localLang)

  // }
  const toggleLang = (e: any) => {
    //if(!load3){
    //setLoad2(true)
    //}

    //setLoad1(false)

    // localStorage.removeItem('lang')
    // localStorage.setItem('lang', `${e.target.id}`)
    //dispatch(addLang(e.target.id))
    let wls: any = window.location.search
      .replace('?lang=', '')
      .replace('code=', '')
      .split('&')

    let value = e.target.id
    let ifl = wls[0]
    let l = value.length ? value : wls[0].length && ifl
    let ifc = wls[1] && wls[1]

    //localStorage.setItem('lang',l?l:localLang.includes('ru')?'RUSSIAN':'ENGLISH')
    //let lang = localStorage.getItem('local')?localStorage.getItem('local')?.includes('RUSSIAN')?'ru-RU':'en' : localLang.includes('ru')?'ru-RU':'en'
    localStorage.setItem('local', value)
    let l2 = value === 'RUSSIAN' ? 'ru-RU' : 'en'
    //dispatch(addLoaded(false))

    if (lang !== l2) {
      dispatch(addLang(l2))
    }

    //dispatch(addWlsLang(value))

    //updateURL(value,ifc)

    setActiveLocal(value)
  }

  const maskClick = (e: any) => {
    setMask((prev) => !prev)
    setMaskId(e.target.id)
    console.log(e.target)
  }

  //console.log(localStorage.getItem('lang'))
  const [promoVal, setPromoVal] = useState('')
  const [mask, setMask] = useState(false)
  const [maskId, setMaskId] = useState('')
  const [step1, setStep1] = useState<any>()
  const [step2, setStep2] = useState<any>()
  const [step3, setStep3] = useState<any>()
  const [step3arr, setStep3arr] = useState<any>()

  const [header, setHeader] = useState<any>()
  const [footer, setFooter] = useState<any>()
  const [isLoaded, setIsLoaded] = useState(false)
  const [lineBetLink, setLineBetLink] = useState('')
  // const[load1,setLoad1]=useState(true)
  // const[load2,setLoad2]=useState(false)
  // const[load3,setLoad3]=useState(false)

  //console.log(maskId)
  // const goToPromo =() =>{
  //    if(codeVal.length&&ready){
  //       setLoad3(true)
  //       setLoad2(false)

  //       //localStorage.setItem('promocode',codeVal)
  //       //updateURL()

  //    }else{
  //       alert(`${lang.includes('en')?'Enter promocode':'Введите промокод'}`)
  //    }

  // }
  const loading = async () => {
    // loading some data

    // call method to indicate that loading is done and we are ready to switch
    loadingContext.done()
  }
  useEffect(() => {
    if (lang.length) {
      fetch(`https://promobot.jsonb.ru/api/get-available-languages.php`, {
        headers: {
          'cache-control': 'no-cache',
          'x-api-key': 'a4BbwoNaAnRFkwsZrJEy1vSkwqyKhlh3',
          'postman-token': '55f458a7-1f3a-0c9b-9e57-e990dd755c4e',
        },
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result.data)
          setStep1Btn(result.data)
          let wls: any = window.location.search
            .replace('?lang=', '')
            .replace('code=', '')
            .split('&')
          let ifl = result.data.find((item: any) => item.name === wls[0])
            ? result.data.find((item: any) => item.name === wls[0]).name
            : localLang.includes('ru')
            ? 'RUSSIAN'
            : 'ENGLISH'
          let l = wls[0].length && ifl
          let ifc = wls[1] && wls[1]
          //if(!localStorage.getItem('lang'))

          //localStorage.setItem('lang',l)
          //console.log('403',l)
          //updateURL(l,ifc)
          //console.log(localStorage.getItem('local')?localStorage.getItem('local')?.includes('RUSSIAN')?'RUSSIAN':'ENGLISH' : localLang.includes('ru')?'RUSSIAN':'ENGLISH')

          if (!activeLangLocal) {
            setActiveLocal(
              localStorage.getItem('local')
                ? localStorage.getItem('local')?.includes('RUSSIAN')
                  ? 'RUSSIAN'
                  : 'ENGLISH'
                : localLang.includes('ru')
                ? 'RUSSIAN'
                : 'ENGLISH'
            )
            if (!localStorage.getItem('local'))
              localStorage.setItem(
                'local',
                localLang.includes('ru') ? 'RUSSIAN' : 'ENGLISH'
              )
          }

          console.log(result)
        })
        .then(() => {
          fetch(
            `https://promocodes.themultiform.com/app/api/header?populate[logo][populate][img][fields]=url&populate[local][populate][img][fields]=url&populate[back][populate][img][fields]=url&populate[download][populate][img][fields]=url&locale=${lang}`
          )
            .then((res) => res.json())
            .then((result) => {
              console.log(result.data.attributes)
              setHeader(result.data.attributes)

              fetch(`https://jsonb.ru/select_mirror_linebet.php`)
                .then((res) => res.json())
                .then((result) => {
                  if (result && result.is_success) {
                    if (
                      result.data.includes('https://') ||
                      result.data.includes('http://')
                    ) {
                      setLineBetLink(result.data)
                    } else {
                      setLineBetLink('https://' + result.data)
                    }
                    console.log(result.data)
                  }
                })
            })

            .then(() => {
              //
              fetch(
                `https://promocodes.themultiform.com/app/api/footer?populate[link][populate][img][fields]=url&populate[copyright][fields]`
              )
                .then((res) => res.json())
                .then((result) => {
                  //console.log(result.data.attributes)
                  setFooter(result.data.attributes)
                })
                .then(() => {
                  setIsLoaded(true)
                })
                .then(() => {
                  dispatch(addLoaded(true))
                  dispatch(addLoad3(false))
                })
            })
        })

        .catch((err) => {
          console.log(err)
        })
    } else {
      let lang = localStorage.getItem('local')
        ? localStorage.getItem('local')?.includes('RUSSIAN')
          ? 'ru-RU'
          : 'en'
        : localLang.includes('ru')
        ? 'ru-RU'
        : 'en'
      //localStorage.setItem('local',lang)
      // setActiveLocal(lang.includes('ru-RU')?'RUSSIAN':'ENGLISH')

      dispatch(addLang(lang))
      let wls: any = window.location.search
        .replace('?lang=', '')
        .replace('code=', '')
        .split('&')
      let ifl = wls[0]
      let l = wls[0].length && ifl
      let ifc = wls[1] && wls[1]
      //console.log('459',l)

      updateURL(l, ifc)

      //if(!localStorage.getItem('lang'))

      // if(localStorage.getItem('lang')&&l){

      //    localStorage.setItem('lang',l)

      //    updateURL(l,ifc)

      // }else{

      //    if(l){
      //       localStorage.setItem('lang',l)

      //    }else{
      //       if(!localStorage.getItem('lang')){
      //          localStorage.setItem('lang',localLang.includes('ru')?'RUSSIAN':'ENGLISH')

      //       }
      //    }

      //    setActiveLocal(localStorage.getItem('local')?.includes('RUSSIAN')?'RUSSIAN':'ENGLISH')

      //    updateURL(localStorage.getItem('local')?localStorage.getItem('lang'):l, ifc)
      // }

      //updateURL(localStorage.getItem('lang')?.includes('RUSSIAN')?'RUSSIAN':'ENGLISH')
    }

    //    if(lang.length){
    //       // setActiveLocal(localStorage.getItem('lang'))
    //       let wls:any=window
    //    .location
    //    .search
    //    .replace('?lang=','').replace('code=','').split('&')
    //    let ifc = wls[1]&&wls[1]

    //    updateURL(localStorage.getItem('lang'),ifc)

    //    fetch(`https://promocodes.themultiform.com/app/api/header?populate[logo][populate][img][fields]=url&populate[local][populate][img][fields]=url&populate[back][populate][img][fields]=url&locale=${lang}`)
    //    .then((res)=>res.json())
    //    .then((result)=>{
    //       //console.log(result.data.attributes)
    //       setHeader(result.data.attributes)
    //    })

    //    .then(()=>{
    //       //
    //       fetch(`https://promocodes.themultiform.com/app/api/footer?populate[link][populate][img][fields]=url&populate[copyright][fields]`)
    //       .then((res)=>res.json())
    //       .then((result)=>{
    //          //console.log(result.data.attributes)
    //          setFooter(result.data.attributes)
    //       })
    //       .then(()=>{
    //          // if(localStorage.getItem('step')){
    //          // setTimeout(() => {
    //          //    setIsLoaded(true)
    //          // }, 200);}else
    //          setIsLoaded(true)

    //       })
    //       .then(()=>{
    //          dispatch(addLoaded(true))
    //          dispatch(addLoad3(false))
    //       })
    //       .then(()=>{

    //          //loading()

    //       })
    //    })
    //    // .then(()=>{

    //    //    fetch(`https://promocodes.themultiform.com/app/api/local?populate[buttons][populate][button][populate][img][fields]=url&locale=${lang}`)

    //    //    .then((res)=>res.json())
    //    //    .then((result)=>{
    //    //       //console.log(result.data.attributes)
    //    //       setStep1(result.data.attributes)
    //    //    })
    //    //    .then(()=>{
    //    //       if(window.location.search.includes('lang')){

    //    //          if(window.location.search.includes('promocode')){
    //    //             console.log('ddldldlldldl')
    //    //             setLoad3(true)

    //    //          }else{
    //    //             setLoad2(true)
    //    //          }
    //    //       }
    //    //       else{
    //    //          setLoadStep1(true)
    //    //       }

    //    //    })
    //    // })

    //    .catch((err)=>{console.log(err)})
    // }
    // // else {

    // //    let wls:any=window
    // //    .location
    // //    .search
    // //    .replace('?lang=','').replace('code=','').split('&')
    // //    let ifl=wls[0]
    // //    let l =wls[0].length&&ifl
    // //    let ifc = wls[1]&&wls[1]
    // //    //if(!localStorage.getItem('lang'))

    // //    if(localStorage.getItem('lang')&&l){

    // //       localStorage.setItem('lang',l)

    // //       updateURL(l,ifc)

    // //    }else{

    // //       if(l){
    // //          localStorage.setItem('lang',l)
    // //       }else{
    // //          if(!localStorage.getItem('lang')){
    // //             localStorage.setItem('lang',localLang.includes('ru')?'RUSSIAN':'ENGLISH')
    // //             console.log('localLang',localLang)
    // //          }
    // //       }
    // //       //localStorage.setItem('lang',l?l:localLang.includes('RUSSIAN')?'RUSSIAN':'ENGLISH')

    // //       setActiveLocal(localStorage.getItem('lang'))
    // //       updateURL(localStorage.getItem('lang'), ifc)
    // //    }

    // //    //updateURL(localStorage.getItem('lang')?.includes('RUSSIAN')?'RUSSIAN':'ENGLISH')

    // // }
  }, [lang, wlsLang])

  const redLink = useRef<any>(null)
  function updateURL(val: any, code: any) {
    //console.log('va',val)
    if (!val || val === 0) {
      //console.log(window.location.pathname)
      if (window.location.pathname === '/') {
        return
      } else {
        let a = document.createElement('a')
        a.href = window.location.protocol + '//' + window.location.host + '/'
        //console.log(redLink.current)
        redLink.current?.click()
        //a.click()
        return
      }
    }
    if (val && val.length) {
      dispatch(addDefLang(val))
    }

    let wls: any = window.location.search
      .replace('?lang=', '')
      .replace('code=', '')
      .split('&')

    if (window.history.replaceState) {
      let ifl = wls[0]
      let l =
        val && val.length
          ? val.includes('RUSSIAN')
            ? 'ru-RU'
            : 'en'
          : wls[0].length && ifl.includes('RUSSIAN')
          ? 'ru-RU'
          : 'en'

      let l2 = val && val.length ? val : wls[0].length && ifl

      var baseUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname
      var newUrl =
        baseUrl +
        `?lang=${l2 ? l2 : localStorage.getItem('lang')}${
          code ? `&code=${code}` : ''
        }`
      window.history.replaceState(null, '', newUrl)

      //dispatch(addLang(l))

      //if(!localStorage.getItem('lang'))
    } else {
      console.warn('History API не поддерживается')
    }
  }

  const MyLoadingScreen = () => {
    return (
      <div className='  w-screen h-screen fixed z-30 top-0 left-0  '>
        <div className='  bg-bgColor flex items-center justify-center w-full h-full'>
          {/* <div className=" lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
        </div>
      </div>
    )
  }

  const [activeLang, setActiveLang] = useState(false)
  const [activeLangH, setActiveLangH] = useState<number>(0)
  const [activeLangLocal, setActiveLocal] = useState<any>()
  const clickLink = (e: any) => {
    if (e.target.className.includes('clickedLang')) {
      setActiveLang((prev) => !prev)

      let list: any = document.querySelector('.langList')
      if (!activeLang) {
        setActiveLangH(list.clientHeight + 8)
      } else {
        setActiveLangH(0)
      }
    } else {
      console.log(e.target)
      if (activeLang) {
        setActiveLang(false)
        setActiveLangH(0)
      }
    }
  }

  // const test1 = () => {
  //    console.log('test1')
  //    localStorage.removeItem('local')
  //    //location.reload()
  // }
  // useEffect(()=>test1)
  useEffect(() => {
    if (localStorage.getItem('step')) {
      localStorage.removeItem('step')
    }

    if (localStorage.getItem('lang')) {
      localStorage.removeItem('lang')
    }
  }, [])

  if (!loaded) {
    return (
      <div className=' w-screen h-screen fixed z-30 top-0 left-0 bg-bgColor '>
        {/* <div className=" lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
      </div>
    )
  } else {
    return (
      <div
        onClick={clickLink}
        className={
          'text-white  mainPromo relative flex flex-col items-center justify-center'
        }
      >
        <Link
          ref={redLink}
          to='/'
          className='w-0 h-0 overflow-hidden opacity-0 top-0 left-0 absolute -z-30'
        ></Link>
        {isLoaded && step1Btn && activeLangLocal && (
          <section className='header flex items-center justify-between w-full absolute top-0 left-1/2 -translate-x-1/2 z-30'>
            <div className='w-fit'>
              <a target={'_blank'} href={lineBetLink} rel='noreferrer'>
                <img
                  src={`https://promocodes.themultiform.com/app${header.logo.img.data.attributes.url}`}
                  alt='logo'
                />
              </a>
            </div>
            <div className={loadedL ? 'w-fit' : 'w-fit pointer-events-none'}>
              <div
                className={
                  'cursor-pointer flex items-center justify-start  clickedLang'
                }
              >
                <img
                  className='mr-1 pointer-events-none h-6 w-6'
                  src={
                    'https://promocodes.themultiform.com/app' +
                    header.local.find(
                      (item: any) => item.local == activeLangLocal.toUpperCase()
                    ).img.data.attributes.url
                  }
                  alt='loc'
                />
                <span className='pointer-events-none uppercase font-bold'>
                  {
                    header.local.find(
                      (item: any) => item.local == activeLangLocal.toUpperCase()
                    ).text
                  }
                </span>
              </div>
              <div className='relative w-full'>
                <div
                  style={{ height: activeLangH + 'px' }}
                  className={
                    'absolute z-50 top-0 left-0 px-1 w-max overflow-hidden duration-200'
                  }
                >
                  <ul
                    className={
                      'flex flex-col items-start justify-start langList relative'
                    }
                  >
                    <div className='listBG'></div>
                    {header.local
                      .filter(
                        (item: any) =>
                          item.local !== activeLangLocal.toUpperCase() &&
                          (item.local === 'RUSSIAN' || item.local === 'ENGLISH')
                      )
                      .map((item: any, index: any) => (
                        <li key={index}>
                          <div
                            id={item.local}
                            onClick={(e) => {
                              toggleLang(e)
                            }}
                            className={
                              'cursor-pointer flex items-center justify-center clickedLang'
                            }
                          >
                            <img
                              className='mr-1 pointer-events-none h-5 w-5'
                              src={
                                'https://promocodes.themultiform.com/app' +
                                item.img.data.attributes.url
                              }
                              alt='loc'
                            />
                            <span className='pointer-events-none uppercase font-bold text-sm'>
                              {item.text}
                            </span>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        )}
        {step1Btn && header && (
          <Routes loadingScreen={MyLoadingScreen}>
            <Route
              path='/'
              element={
                <StepOne
                  step1Btn={step1Btn}
                  updateURL={updateURL}
                  setActiveLocal={setActiveLocal}
                />
              }
              loading={localStorage.getItem('step') ? false : true}
            />

            <Route
              path='/step_two'
              element={<StepTwo updateURL={updateURL} header={header} />}
              loading
            />
            <Route
              path='/step_three'
              element={<StepThree updateURL={updateURL} header={header} />}
              loading
            />
          </Routes>
        )}

        {isLoaded && (
          <section className=' bg-bgColor-light'>
            <div className='footer z-10 bg-bgColor-light'>
              <div className='footer-cntnr flex items-center  justify-between w-full'>
                <div className='w-fit text-grey sm:pr-4 footer-link'>
                  <div>{`Copyright ©${new Date().getFullYear()}`}</div>
                </div>
                <div className='flex item-center'>
                  {footer.link.map((item: any) => (
                    <div key={item.id} className='mr-2.5 last:mr-0'>
                      <a target={'_blank'} href={item.link} rel='noreferrer'>
                        <img
                          src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`}
                          alt='link'
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
        {/* {
         (load1&&loadStep1)&&

         <section className='w-full  flex items-center justify-center localSection'>
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 sm:w-auto w-full sm:px-0 px-2.5'>
         
            <div className='bg-bgColor-light flex flex-col items-center justify-center localContainer'>
               <h2 className='font-semibold text-xl pb-8' >{step1.title}</h2>
               <Link to='/step_two' className='flex items-center justify-center sm:flex-row flex-col'>
                  {
                     step1.buttons.button.map((item:any)=>(
                        <button onClick={(e)=>toggleLang(e)} id={item.local} key={item.id} className='hover:bg-green duration-300 sm:first:mr-3 sm:first:mb-0 first:mb-4 sm:last:ml-3 localBtn py-3 w-40  border-green flex items-center justify-center font-extrabold uppercase'>
                           <img className='mr-2 pointer-events-none' src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="lang" />
                           <span className='pointer-events-none'>{item.text}</span>
                        </button>
                     ))
                  }
               </Link>
            </div>
            </div>

         
         </section>
      
      }  */}
        {/* {
         (load2&&loadStep2)&&
         <section className='w-full  flex flex-col items-center justify-center'>
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 sm:w-auto w-full sm:px-0 px-2.5'>
            <div className='pb-2.5 '>
                  <button onClick={back2} className='flex items-center justify-center uppercase font-bold'>
                     <img className='mr-1' src={`https://promocodes.themultiform.com/app${header.back.img.data.attributes.url}`} alt="back" />
                     <span>{header.back.text}</span>
                  </button>
               </div>
            <div className='bg-bgColor-light flex flex-col items-center justify-center promoContainer'>
               <h2 className='font-semibold text-xl pb-8 px-2.5' >{step2.title}</h2>
               <div className='flex flex-col items-start justify-center'>
                  <label className='font-semibold text-sm pb-2.5 ' htmlFor="">{step2.input.label}</label>
                  <input className='w-full px-3 py-3 font-semibold  text-sm bg-grey-dark text-grey promoInput' type="text" onChange={(e)=>funcT(e)}  placeholder={codeVal}/>
            
                  <div className='text-white uppercase font-semibold text-center pt-5'>
                  
                     <button onClick={goToPromo} className='bg-green flex items-center justify-berween   btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
                     
                        <div className='bg-green-dark h-full flex items-center justify-center btnPromoImg'>
                           <img src={`https://promocodes.themultiform.com/app${step2.button.img.data.attributes.url}`} alt="btn" />
                        </div>
                        <div className='px-10  '>
                           <span>{step2.button.text}</span>
                        </div>
                     
                     </button>
                  </div>
               </div>

            
            </div>
            </div>

         
         </section>
      
      }    */}

        {/* {
         (load3&&loadStep3)&&
         <section className='mx-auto w-fit bigGrid'>
            <div className='pb-2.5 pl-2.5'>
               <button onClick={()=>{
                  setLoad3(false)
                  setLoad2(true)
               }} className='flex items-center justify-center uppercase font-bold'>
                  <img className='mr-1' src={`https://promocodes.themultiform.com/app${header.back.img.data.attributes.url}`} alt="back" />
                  <span>{header.back.text}</span>
               </button>
            </div>
            <div className='bg-bgColor-light flex flex-col items-center justify-center placeContainer'>
            <h2 className='font-semibold text-xl pb-8' >{step3.title}</h2>
            {
               step3arr.map((item:any,index:any)=>(
                  <div key={index} className='pt-2.5'>

                  <div className=' placesBody1 cursor-pointer'>
                     {
                        item.slice(0,4).map((item:any)=>(
                           <div key={item.id} className='flex flex-col items-center justify-center flexPromo1 '>
                              <div id={item.id} onClick={(e)=>maskClick(e)} className='flex flex-col items-center justify-center placesCntnr '> 
                                 <div   className='imgPlace relative pointer-events-none '>
                                    <div  className='relative' >
                                       <img src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                       <Promocode  place={item.place} codeVal={codeVal}/>
                                    </div>
                                    
                                 </div>
                              </div>
                              <div id={item.id}  className={'mask-bg opacity-0 pointer-events-none duration-300'}>
                                 <div className='flex flex-col items-center justify-center mask'>
                                    <div id={item.id} onClick={(e)=>maskClick(e)}  className='mask-exit'>X</div>
                                    <div className='flex flex-col items-center justify-center placesCntnr'> 
                                       <div  ref={maskId==item.id?myRes:null} className='imgPlace relative'>
                                          <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                          <Promocode  place={item.place} codeVal={codeVal}/>
                                       </div>
                                    </div>


                              
                                    <button onClick={onButtonClick} className='bg-green mt-5 mx-auto flex items-center justify-berween w-60 font-extrabold uppercase  btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
                                       <div className='bg-green-dark h-full flex items-center justify-center btnPromoImg'>
                                          <img src={`https://promocodes.themultiform.com/app${item.button.img.data.attributes.url}`} alt="" />
                                       </div>
                                       <div className='px-10  '>
                                          <span>{item.button.text}</span>
                                       </div>
                                    
                                    
                                    </button>

                                 </div>
                              </div>
                           
                           
                           </div>
                        ))
                     
                     }
                  </div>
                  {
                        item.slice(4,11).length?
                        <div className=' placesBody2 cursor-pointer'>

                        
                              {item.slice(4,11).map((item:any)=>(
                                 <div key={item.id} className='flex flex-col items-center justify-center flexPromo2'>
                                    <div id={item.id} onClick={(e)=>maskClick(e)} className='flex flex-col items-center justify-center placesCntnr '> 
                                       <div   className='imgPlace relative pointer-events-none'>
                                       <div  className='relative' >
                                          <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                          <Promocode  place={item.place} codeVal={codeVal}/>
                                       </div>
                                       </div>
                                    </div>
                                    <div id={item.id}  className={'mask-bg opacity-0 pointer-events-none duration-300'}>
                                       <div className='flex flex-col items-center justify-center mask'>
                                       <div id={item.id} onClick={(e)=>maskClick(e)} className='mask-exit'>X</div>
                                          <div className='flex flex-col items-center justify-center placesCntnr'> 
                                             <div  ref={maskId==item.id?myRes:null} className='imgPlace relative'>
                                                <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                                <Promocode  place={item.place} codeVal={codeVal}/>
                                             </div>
                                          </div>


                                    
                                          <button onClick={onButtonClick} className='bg-green mt-5 mx-auto flex items-center justify-berween w-60 font-extrabold uppercase  btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
                                             <div className='bg-green-dark h-full flex items-center justify-center btnPromoImg'>
                                                <img src={`https://promocodes.themultiform.com/app${item.button.img.data.attributes.url}`} alt="" />
                                             </div>
                                             <div className='px-10  '>
                                                <span>{item.button.text}</span>
                                             </div>
                                          
                                          
                                          </button>

                                       </div>
                                    </div>
                                 
                                 
                                 </div>

                              ))
                           }
                        </div>
                        :
                        <div className='hidden'></div>
                  }
                  {
                        item.slice(11,18).length?
                        <div className=' placesBody3 cursor-pointer'>

                        
                              {item.slice(11,18).map((item:any)=>(
                                 <div key={item.id} className='flex flex-col items-center justify-center flexPromo3'>
                                    <div id={item.id} onClick={(e)=>maskClick(e)} className='flex flex-col items-center justify-center placesCntnr '> 
                                       <div   className='imgPlace relative pointer-events-none'>
                                       <div  className='relative' >
                                          <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                          <Promocode  place={item.place} codeVal={codeVal}/>
                                       </div>
                                       </div>
                                    </div>
                                    <div id={item.id}  className={'mask-bg opacity-0 pointer-events-none duration-300'}>
                                       <div className='flex flex-col items-center justify-center mask'>
                                       <div id={item.id} onClick={(e)=>maskClick(e)} className='mask-exit'>X</div>
                                          <div className='flex flex-col items-center justify-center placesCntnr'> 
                                             <div  ref={maskId==item.id?myRes:null} className='imgPlace relative'>
                                                <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                                <Promocode  place={item.place} codeVal={codeVal}/>
                                             </div>
                                          </div>


                                    
                                          <button onClick={onButtonClick} className='bg-green mt-5 mx-auto flex items-center justify-berween w-60 font-extrabold uppercase  btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
                                             <div className='bg-green-dark h-full flex items-center justify-center btnPromoImg'>
                                                <img src={`https://promocodes.themultiform.com/app${item.button.img.data.attributes.url}`} alt="" />
                                             </div>
                                             <div className='px-10  '>
                                                <span>{item.button.text}</span>
                                             </div>
                                          
                                          
                                          </button>

                                       </div>
                                    </div>
                                 
                                 
                                 </div>

                              ))
                           }
                        </div>
                        :
                        <div className='hidden'></div>
                  }
                  {
                        item.slice(18,22).length?
                        <div className=' placesBody4 cursor-pointer'>

                        
                              {item.slice(18,22).map((item:any)=>(
                                 <div key={item.id} className='flex flex-col items-center justify-center flexPromo4'>
                                    <div id={item.id} onClick={(e)=>maskClick(e)} className='flex flex-col items-center justify-center placesCntnr '> 
                                       <div   className='imgPlace relative pointer-events-none'>
                                       <div  className='relative' >
                                          <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                          <Promocode  place={item.place} codeVal={codeVal}/>
                                       </div>
                                       </div>
                                    </div>
                                    <div id={item.id}  className={'mask-bg opacity-0 pointer-events-none duration-300'}>
                                       <div className='flex flex-col items-center justify-center mask'>
                                       <div id={item.id} onClick={(e)=>maskClick(e)} className='mask-exit'>X</div>
                                          <div className='flex flex-col items-center justify-center placesCntnr'> 
                                             <div  ref={maskId==item.id?myRes:null} className='imgPlace relative'>
                                                <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                                <Promocode  place={item.place} codeVal={codeVal}/>
                                             </div>
                                          </div>


                                    
                                          <button onClick={onButtonClick} className='bg-green mt-5 mx-auto flex items-center justify-berween w-60 font-extrabold uppercase  btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
                                             <div className='bg-green-dark h-full flex items-center justify-center btnPromoImg'>
                                                <img src={`https://promocodes.themultiform.com/app${item.button.img.data.attributes.url}`} alt="" />
                                             </div>
                                             <div className='px-10  '>
                                                <span>{item.button.text}</span>
                                             </div>
                                          
                                          
                                          </button>

                                       </div>
                                    </div>
                                 
                                 
                                 </div>

                              ))
                           }
                        </div>
                        :
                        <div className='hidden'></div>
                  }
                  </div>
               ))
            }
            </div>

         
         </section>
      }
      {
         (load3&&loadStep3)&&
         <section className='mx-auto w-fit smallGrid'>
            <div className='pb-2.5 pl-2.5'>
               <button onClick={()=>{
                  setLoad3(false)
                  setLoad2(true)
               }} className='flex items-center justify-center uppercase font-bold'>
                  <img className='mr-1' src={`https://promocodes.themultiform.com/app${header.back.img.data.attributes.url}`} alt="back" />
                  <span>{header.back.text}</span>
               </button>
            </div>
            <div className='sm:bg-bgColor-light flex flex-col items-center justify-center placeContainerSm'>
            <h2 className='font-semibold text-xl pb-8 px-2.5' >{step3.title}</h2>
            {
               step3arr.map((item:any,index:any)=>(
                  <div key={index} className='pt-2.5'>

                  <div className=' placesBody1sm cursor-pointer'>
                     {
                        item.slice(0,3).map((item:any)=>(
                           <div key={item.id} className='flex flex-col items-center justify-center flexPromo1'>
                              <div id={item.id} onClick={(e)=>maskClick(e)} className='flex flex-col items-center justify-center placesCntnr '> 
                                 <div   className='imgPlace relative pointer-events-none'>
                                 <div  className='relative' >
                                    <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                    <Promocode  place={item.place} codeVal={codeVal}/>
                                 </div>
                                 </div>
                              </div>
                              <div id={item.id}  className={'mask-bgSm p-8 opacity-0 pointer-events-none duration-300'}>
                                 <div className='flex flex-col items-center justify-center mask '>
                                    <div id={item.id} onClick={(e)=>maskClick(e)}  className='mask-exit'>X</div>
                                    <div className='flex flex-col items-center justify-center'> 
                                       <div  ref={maskId==item.id?myResSm:null} className='imgPlace relative'>
                                          <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                          <Promocode  place={item.place} codeVal={codeVal}/>
                                       </div>
                                    </div>


                              
                                    <button onClick={onButtonClickSm} className='bg-green mt-5 mx-auto flex items-center justify-berween w-60 font-extrabold uppercase  btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
                                       <div className='bg-green-dark h-full flex items-center justify-center btnPromoImg'>
                                          <img src={`https://promocodes.themultiform.com/app${item.button.img.data.attributes.url}`} alt="" />
                                       </div>
                                       <div className='px-10  '>
                                          <span>{item.button.text}</span>
                                       </div>
                                    
                                    
                                    </button>

                                 </div>
                              </div>
                           
                           
                           </div>
                        ))
                     
                     }
                  </div>
                  {
                        item.slice(3,6).length?
                        <div className=' placesBody2sm cursor-pointer'>

                        
                              {item.slice(3,6).map((item:any)=>(
                                 <div key={item.id} className='flex flex-col items-center justify-center flexPromo2'>
                                    <div id={item.id} onClick={(e)=>maskClick(e)} className='flex flex-col items-center justify-center placesCntnr '> 
                                       <div   className='imgPlace relative pointer-events-none'>
                                       <div  className='relative' >
                                          <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                          <Promocode  place={item.place} codeVal={codeVal}/>
                                       </div>
                                       </div>
                                    </div>
                                    <div id={item.id}  className={'mask-bgSm p-8 opacity-0 pointer-events-none duration-300'}>
                                       <div className='flex flex-col items-center justify-center mask'>
                                       <div id={item.id} onClick={(e)=>maskClick(e)} className='mask-exit'>X</div>
                                          <div className='flex flex-col items-center justify-center'> 
                                             <div  ref={maskId==item.id?myResSm:null} className='imgPlace relative'>
                                                <img  src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
                                                <Promocode  place={item.place} codeVal={codeVal}/>
                                             </div>
                                          </div>


                                    
                                          <button onClick={onButtonClickSm} className='bg-green mt-5 mx-auto flex items-center justify-berween w-60 font-extrabold uppercase  btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
                                             <div className='bg-green-dark h-full flex items-center justify-center btnPromoImg'>
                                                <img src={`https://promocodes.themultiform.com/app${item.button.img.data.attributes.url}`} alt="img" />
                                             </div>
                                             <div className='px-10  '>
                                                <span>{item.button.text}</span>
                                             </div>
                                          
                                          
                                          </button>

                                       </div>
                                    </div>
                                 
                                 
                                 </div>

                              ))
                           }
                        </div>
                        :
                        <div className='hidden'></div>
                  }
                  </div>
               ))
            }
            </div>

         
         </section>
      }
*/}

        {/* <section className='pt-28 flex flex-wrap -m-1'>
       {
          imgs.map((item:any)=>(
             <div key={item.id} className=' xl:w-1/4 md:w-1/3 sm:w-1/2  p-1'>
                <div id={item.id} ref={imgRes===item.id?myRes:null} className='relative resultImg'>
                   <img  src={item.url} alt="img" />
                   <Promocode places={places} place={place} codeVal={codeVal}/>
                </div>

                <div className=' text-white  font-bold text-center py-4'>
                   <button disabled={btnFlag?true:false} id={item.id} onClick={(e)=>onButtonClick(e)} className='bg-blue-light py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200'>Сохранить</button>
                </div>
             </div>
          ))
       }
    </section> */}

        {/* <div className='z-30 fixed top-4 right-8'>
       <button className='text-blue-solight hover:text-blue'> <Link  to='/old'>Old</Link></button>
    </div>  */}

        {/* <div className='text-white  font-bold text-center pt-8'>
//       <button onClick={downloadZip} className='bg-blue-light w-full py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200'>
//          Сохранить все
//       </button>
      
//    </div> */}
      </div>
    )
  }
}

export default MainPromo
