import React,{FC,useEffect,useState,useCallback,useRef} from 'react'
import { useLoadingContext } from "react-router-loading";
import { Link } from "react-router-dom";
import Promocode from './Promocode';
import { toPng,toJpeg,toBlob} from 'html-to-image';
import {addLoadedL,actualLoad3, actualWlsLang,addLoad3,actualLang,actualCode,addCode,addDefLang,actualDefLang} from '../features/counter/counterSlice';
import { useAppDispatch,useAppSelector } from '../app/hooks';
import FileSaver,{ saveAs } from 'file-saver';
const StepThree:FC<any> = ({updateURL,header}) => {
   
   const [promocodesArr,setPromocodesArr]=useState<any>()
   const mycode=useAppSelector(actualCode)
   const defLang=useAppSelector(actualDefLang)
   const lang = useAppSelector(actualLang)
   const dispatch = useAppDispatch()
   const loadingContext = useLoadingContext();
   let localLang:string = window.navigator.language
   const load3 = useAppSelector(actualLoad3)
   const[step3arr,setstep3arr]=useState<any>()
   
   const[step3,setStep3]=useState<any>()
   const [loadStep3,setLoadStep3]=useState(false)
   //const[header,setHeader]=useState<any>()
   const wlsLang=useAppSelector(actualLang)
   //promocode func 
   const[codeVal,setCodeVal]=useState<any>('')
const[scrolled,setScrolled]=useState(false)
useEffect(()=>{
   if(scrolled){
      
         console.log('scroll3')
         window.scrollTo(0,0)
      
      
   }
},[scrolled])

useEffect(()=>{
   dispatch(addLoadedL(false))
},[])
   const loading = async () => {
      // loading some data
      
     
      
      setTimeout(() => {
         dispatch(addLoadedL(true))
         // document.querySelectorAll('.code-cntnr')?.forEach((el:any) => {
         //    el.classList.add('noactive')
         // })
       
      // call method to indicate that loading is done and we are ready to switch
      if(!scrolled){
         setScrolled(true)
      }
      
      loadingContext.done();
      dispatch(addLoad3(true))

      
      
      }, 500);
      
   };
   useEffect(()=>{
      let array:any
      let val:string
      let wls:any=window
      .location
      .search
      .replace('?lang=','').replace('code=','').split('&')

      
      
      if(wls[1]&&wls[1].length){
         dispatch(addCode(decodeURIComponent(wls[1])))
         setCodeVal(decodeURIComponent(wls[1]))
         val=decodeURIComponent(wls[1])
      }else{
         //dispatch(addCode('no promo'))
         val='no promo'
         setCodeVal('no promo')
      }
      

      
      // let ifc = wls[1]?wls[1]:mycode
      
      //    let ifl=wls[0]
      //    let l =(wls[0].length&&ifl!=='null')?ifl:defLang
      // updateURL(l,ifc)

      //dispatch(addLang(wls[0].includes('RUSSIAN')?'RUSSIAN':'ENGLISH'))
      
      
      if(lang.length){
         console.log('start step3')
        
         
            
               
            fetch(`https://promocodes.themultiform.com/app//api/images-for-promocode?populate[PromoPlace][populate][place][fields]&populate[PromoPlace][populate][button][populate][img][fields]=url&populate[PromoPlace]][populate][img][fields]=url&locale=${lang}`)
            .then((res)=>res.json())
            .then((result)=>{
               //console.log(result.data.attributes)
               setStep3(result.data.attributes)
               //console.log(result.data.attributes.PromoPlace)
   
               array = result.data.attributes.PromoPlace; //массив, можно использовать массив объектов
               let size = 18; //размер подмассива
               let subarray = []; //массив в который будет выведен результат.
   
               for (let i = 0; i <Math.ceil(array.length/size); i++){
                  subarray[i] = array.slice((i*size), (i*size) + size);
               }
               
               
               //window.addEventListener('resize',()=>resFunc(array),true)
               //setWinw(true)
               //setstep3arr(subarray);
            })

            .then(()=>{
               if(!step3arr){
                  var data = `{\n\t\"language\":\"${wls[0]}\",\n\t\"promocode\":\"${val}\"\n}`;
                  let result =  fetch("https://promobot.jsonb.ru/api/create-promocode.php",{
                     method:'post',
                     headers:{
                        'cache-control': 'no-cache',
                        'x-api-key':'a4BbwoNaAnRFkwsZrJEy1vSkwqyKhlh3',
                     },
                     body:data
                  })
                  result.then((response) => response.json())
                  .then((data) =>{ 
                     
                     
                     console.log('data',data.data)
                  array = data.data; //массив, можно использовать массив объектов
                  let size = 18; //размер подмассива
                  let subarray = []; //массив в который будет выведен результат.
      
                  for (let i = 0; i <Math.ceil(array.length/size); i++){
                     subarray[i] = array.slice((i*size), (i*size) + size);
                  }
                  setstep3arr(subarray);
                  console.log('step3results',data)});
               }
            })
            .then(()=>{
               setLoadStep3(true)
               setTimeout(() => {
                  document.querySelectorAll('.code-cntnr')?.forEach((el:any) => {
                     el.classList.add('noactive')
                  })
               }, 500);
               
            })
         
         .catch((err)=>{console.log(err)})
      }
      
      
      
   
    
   },[lang,wlsLang])

   
   let winwB=false
   let winwS=false
   // useEffect(()=>{
   //    //console.log(step3)
   //    if(winw){
   //       const resFunc = ()=>{
   //          console.log(winw)
   //          if(window.innerWidth<997){
   //             //console.log(arr)
               
   //             if(!winw){
                  
   //                console.log(step3)
   //             }
               
   //          }
   //       }
   //       window.addEventListener('resize',()=>resFunc())
   //    }
      
   // },[winw])
   


   const [activeSrc,setActiveSrc]=useState('')
   
   const maskClick = (e:any) =>{
   
      if(e.target.className!=='mask-exit'){
         
         setActiveSrc(e.target.id)
      }else{
         setActiveSrc('')
      }
      setMask(prev=>!prev)
      setMaskId(e.target.id)
   }
   const[mask,setMask]=useState(false)
const[maskId,setMaskId]=useState('')
useEffect(()=>{
   
   let msk = document.querySelectorAll('.mask-bg')
   for (let i = 0; i < msk.length; i++) {
      if(maskId===msk[i].id){
         
         msk[i].classList.toggle('active')

         break
      }
   }
   let mskSm = document.querySelectorAll('.mask-bgSm')
   for (let i = 0; i < mskSm.length; i++) {
      if(maskId===mskSm[i].id){
         
         mskSm[i].classList.toggle('active')

         break
      }
   }

},[mask])
   //Saving result
   const origImg = useRef<any>(null)
   const origImgSm = useRef<any>(null)
   const myRes = useRef<HTMLDivElement>(null)
   const myResSm = useRef<any>(null)
   const [imgRes, setImgRes] = useState<any>()
   const [btnFlag, setIBtnFlag] = useState(false)
   //console.log('hi1')
   const download = async (src:string) =>{
      console.log(src)
      src=src.replace('https://promobot.jsonb.ru/results/','')
      console.log(src)
      //FileSaver.saveAs(src, "yourPromo");
      let link = document.createElement("a");
      link.setAttribute("href",  `https://promobot.jsonb.ru/api/image-download.php?path=${src}`);
      link.setAttribute("download", `yourPromo`);
      link.click();
   }
   const onButtonClick = useCallback((e:any) => {
      //console.log(origImg.current.naturalWidth, myRes.current?.clientWidth)
      setImgRes(e.target.id)

      if(!btnFlag){
         
         setIBtnFlag(true)
         
         setTimeout(() => {
            console.log('onButtonClick',myResSm.current)
            if (myResSm.current === null) {
               
               return
            }



            

            // myRes.current.style.opacity='1'
            // myRes.current.className='imgPlace relative'
            

            // fetch(`https://promobot.jsonb.ru/api/image-download.php?path=RUSSIAN/TEST/casino_31666251709.jpg`,{
               
            //    headers:{
            //       'cache-control': 'no-cache',
            //       'x-api-key':'a4BbwoNaAnRFkwsZrJEy1vSkwqyKhlh3',
            //    },
            // })

            // .then((res)=>res.blob())
            // .then(()=>{download(myResSm.current.src)})
            
            // .then(()=>setIBtnFlag(false))
            download(myResSm.current.src)
            .then(()=>{
               setIBtnFlag(false)
            })

            // myResSm.current.style.width=origImgSm.current.naturalWidth+'px'
            
            // origImgSm.current.style.maxHeight=origImgSm.current.naturalHeight+'px'


            // toPng(myResSm.current, { cacheBust: true, width:origImgSm.current.naturalWidth ,height:origImgSm.current.naturalHeight})

            // .then((dataUrl:string) => {
               

            //    const link = document.createElement('a')
            //    link.download = 'yourPromo.png'
            
            //    link.href = dataUrl
            //    console.log(link.href)
            //    //link.click()
               

            // })
            // .then(()=>{
            //    setIBtnFlag(false)

            // //    if(myRes.current!==null){
            // //    myRes.current.style.opacity='1'
            // //    myRes.current.className='imgPlace relative duration-200'
            // //    origImg.current.style.width='auto'
               
            // //    origImg.current.style.maxHeight='85vh'
            
            // // }

               
            // })
            // .catch((err:any) => {
            //    console.log(err)
            // })
         }, 200);
      }
      
   }, [myResSm])


   const code=useRef<HTMLDivElement>(null)
   const prcode=useRef<HTMLDivElement>(null)

   const codeColor=useRef<HTMLDivElement>(null)
   const prcodeColor=useRef<HTMLDivElement>(null)
   const codeImageBlob = useCallback(() => {
      //console.log(origImg.current.naturalWidth, myRes.current?.clientWidth)
  

     
         
         // setTimeout(() => {
            
         //    if (code.current === null||prcode.current===null) {
               
         //       return
         //    }
            

         //    toBlob(code.current, { cacheBust: true,style:{opacity:'1'}, width:prcode.current.clientWidth , height:prcode.current.clientHeight})

         //    .then((dataUrl:any) => {
               

         //       const link = document.createElement('a')
         //       link.download = 'yourPromo.png'
         //       //setCodeImg(dataUrl)
         //       link.href = dataUrl
         //       console.log('toBlob',dataUrl)
         //       //link.click()
         //       let formData = new FormData()
         //       formData.append('yourPromo', dataUrl, 'yourPromo.png')
               

         //       fetch('/app/api/upload', {
         //          method: 'post',
         //          body: formData
         //       });

         //    })
         //    // .then(()=>{
         //    //    setTimeout(() => {
         //    //       document.querySelectorAll('.code-cntnr')?.forEach((el:any) => {
         //    //          el.classList.remove('noactive')
         //    //       })
         //    //    }, 200);
               
         //    // })
         //    .catch((err:any) => {
         //       console.log(err)
         //    })
         // }, 100);
         let img:any = document.querySelector('.sendCode')
         console.log(img.src)
         //FileSaver.saveAs(img.src, "image.jpg");

         let file = {url: img.src}
         let data = new FormData()
         data.append('file',JSON.stringify(file))

      fetch(`https://promocodes.themultiform.com/uploads`,{
         method:'post',
         body:data

      })
      .then((res)=>console.log(res))
      

   }, [code])
   const [codeImg,setCodeImg]=useState('')
   const codeImage = useCallback(() => {
      //console.log(origImg.current.naturalWidth, myRes.current?.clientWidth)
  

     
         
         setTimeout(() => {
            
            if (code.current === null||prcode.current===null) {
               
               return
            }
            

            toPng(code.current, { cacheBust: true,style:{opacity:'1'}, width:prcode.current.clientWidth , height:prcode.current.clientHeight})

            .then((dataUrl:string) => {
               

               const link = document.createElement('a')
               link.download = 'yourPromo.png'
               setCodeImg(dataUrl)
               link.href = dataUrl
               //console.log('codeImage',dataUrl)
               //link.click()
               // let formData = new FormData()
               // formData.append('yourPromo', dataUrl, 'yourPromo.png')
               // fetch('api/upload', {
               //    method: 'post',
               //    body: formData
               //  });

            })
            .then(()=>{
               setTimeout(() => {
                  document.querySelectorAll('.code-cntnr')?.forEach((el:any) => {
                     el.classList.remove('noactive')
                  })
               }, 200);
               
            })
            .catch((err:any) => {
               console.log(err)
            })
         }, 100);
      
      
   }, [code])
   const [codeImgColor,setCodeImgColor]=useState('')
   const codeImageColor = useCallback(() => {
      //console.log(origImg.current.naturalWidth, myRes.current?.clientWidth)
  

     
         
         setTimeout(() => {
            if (codeColor.current === null||prcodeColor.current===null) {
               
               return
            }
            

            
            toPng(codeColor.current, { cacheBust: true,style:{opacity:'1'}, width:prcodeColor.current.clientWidth, height:prcodeColor.current.clientHeight})

            .then((dataUrl:string) => {
               

               const link = document.createElement('a')
               link.download = 'yourPromo.png'
               setCodeImgColor(dataUrl)
               link.href = dataUrl
              //console.log(codeColor.current)
               //link.click()
               

            })
            
            .catch((err:any) => {
               console.log(err)
            })
         }, 100);
      
      
   }, [codeColor])

   const[goPromo,setGoPromo]=useState(false)
   

   const badPictures = {
      UZBEKISTAN:[
      'https://promobot.jsonb.ru/results/UZBEKISTAN/1234AWUYQFGT3/LineBet_promocode1_1666603583.mp4',
      ],
      ENGLISH:[
      'https://promobot.jsonb.ru/results/ENGLISH/1234AWUYQFGT3/render_code_1666603536.mp4',
      'https://promobot.jsonb.ru/results/ENGLISH/1234AWUYQFGT3/sevrole_football1666603536.jpg',
      ],
      RUSSIAN:[
      'https://promobot.jsonb.ru/results/RUSSIAN/1234AWUYQFGT3/Linebet_green_all1666604127.mp4'
      ],
      ARABIC:[
      'https://promobot.jsonb.ru/results/ARABIC/1234AWUYQFGT3/Linebet_green_all1666604235.mp4'
      ],
      FRANCE:[
      'https://promobot.jsonb.ru/results/FRANCE/1234AWUYQFGT3/fr_141666604374.jpg',

      ],
      INDIA:[
      'https://promobot.jsonb.ru/results/INDIA/1234AWUYQFGT3/card1666604487.jpg',
      
      ],
      SOMALI:[
      'https://promobot.jsonb.ru/results/SOMALI/1234AWUYQFGT3/so_men_new1666604687.jpg',
      'https://promobot.jsonb.ru/results/SOMALI/1234AWUYQFGT3/fotball_so1666604687.jpg',
      'https://promobot.jsonb.ru/results/SOMALI/1234AWUYQFGT3/render_code_1666604688.mp4',
      'https://promobot.jsonb.ru/results/SOMALI/1234AWUYQFGT3/mbape_so1666604687.jpg'

      ],
      SWAHILI:[
      'https://promobot.jsonb.ru/results/SWAHILI/1234AWUYQFGT3/fotball_so1666604874.jpg',
      'https://promobot.jsonb.ru/results/SWAHILI/1234AWUYQFGT3/mbape_so1666604874.jpg',
      'https://promobot.jsonb.ru/results/SWAHILI/1234AWUYQFGT3/africa1666604874.jpg',
      'https://promobot.jsonb.ru/results/SWAHILI/1234AWUYQFGT3/afro_men_new1666604874.jpg',
      'https://promobot.jsonb.ru/results/SWAHILI/1234AWUYQFGT3/afro_women1666604874.jpg',

      ],
      MONGOLIA:[
      'https://promobot.jsonb.ru/results/MONGOLIA/1234AWUYQFGT3/Linebet_green_all1666605017.mp4',

      ],
      PAKISTANI:[
      'https://promobot.jsonb.ru/results/PAKISTANI/1234AWUYQFGT3/Linebet_green_all1666605098.mp4'
      ],
      BANGLADESH:[
      'https://promobot.jsonb.ru/results/BANGLADESH/1234AWUYQFGT3/beshi_odd1666605161.jpg',
      'https://promobot.jsonb.ru/results/BANGLADESH/1234AWUYQFGT3/sera_offer1666605161.jpg',
      'https://promobot.jsonb.ru/results/BANGLADESH/1234AWUYQFGT3/winzo1666605161.jpg',
      'https://promobot.jsonb.ru/results/BANGLADESH/1234AWUYQFGT3/fight1666605161.mp4'

      ],
   }
 //useEffect(()=>{
   
//  if(goPromo)
//  setTimeout(() => {
//    codeImage()
//    codeImageColor()
//    //codeImageBlob()
//  }, 400);
 
//  },[goPromo])



//  {promocodesArr&&

//    <div  className='pt-2.5'>

//    <div className=' placesBody1 cursor-pointer'>
//       {
//          promocodesArr.map((item:any,index:any)=>(
//             //.slice(0,4)
//             <div key={index} className='flex flex-col items-center justify-center  flexPromo1 '>
               
               
//                <div id={item.id} onClick={(e)=>maskClick(e)} className='block placesCntnr '> 
//                   <div   className='imgPlace code-cntnr relative pointer-events-none '>
//                      <div  className='relative ' >
//                      {/* onLoad={loading} */}
                     
//                         <img className={'imgPlace__img '}  src={item.src} alt="img" />

                     
//                      </div>
                     
//                   </div>
//                </div>
               
//                <div id={item.id}  className={'mask-bg opacity-0 pointer-events-none duration-300'}>
//                   <div className='mask-bg__container'>
//                   <div className='flex flex-col items-center justify-center  mask   relative'>
//                      <div id={item.id} onClick={(e)=>maskClick(e)}  className='mask-exit'>X</div>
//                      <div className='flex flex-col items-center justify-center placesCntnr'> 
//                         <div   className='imgPlace relative flex items-start justify-center'>
                           
//                               <img className={'imgPlace__img'} src={item.src} alt="img" />
                              
                        
                        
//                         </div>
                        
//                      </div>
                     

               
//                      <button onClick={onButtonClick} className='bg-green mt-5 mx-auto flex items-center justify-berween w-60 font-extrabold uppercase  btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
//                         <div className='bg-green-dark  flex items-center justify-center btnPromoImg'>
                           
//                         </div>
//                         <div className='px-10  '>
//                            <span>download</span>
//                         </div>
                     
                     
//                      </button>

//                   </div>
//                   </div>

//                </div>
            
            
//             </div>
//          ))
      
//       }
//    </div>
// </div>
// }
// {
//    step3.PromoPlace.map((item:any)=>(
//       <div key={item.id}  className='fixed  top-0  left-0  opacity-0 pointer-events-none  downloaded'>
//          <div ref={maskId==item.id?myResSm:null} className='images relative w-max h-max'>
//             <img onLoad={()=>{setGoPromo(true)}} className=' object-none '  ref={maskId==item.id?origImgSm:null} src={`https://promocodes.themultiform.com/app${item.img.data.attributes.url}`} alt="img" />
//             <Promocode promoHeight={'h-3/4'} codeImg={item.place.color?codeImgColor:codeImg}  size={'16px'}  place={item.place} codeVal={codeVal}/>
//          </div>
//       </div>
//    ))
// }
   return (
      <>
         {
      (loadStep3)&&
      
      <div className='downloadSection'> 
         
         {/* <div className=' overflow-hidden h-0  opacity-0 font-bold leading-none uppercase' style={{width:'0px',fontFamily: 'monospace' }} ref={code}>
            <div className='w-fit h-fit text-xxl ' ref={prcode}>{codeVal}</div>
         </div>
         <div className=' overflow-hidden h-0 opacity-0 font-bold leading-none uppercase' style={{width:'0px',fontFamily: 'monospace' }} ref={codeColor}>
            <div className='w-fit text-black h-fit text-xxl' ref={prcodeColor}>{codeVal}</div>
         </div>
            <img className='sendCode w-40 h-20' src={`https://promocodes.themultiform.com/app${step3.PromoPlace[0].img.data.attributes.url}`} alt="" /> */}
            {/* {step3arr&& 
                  step3arr.map((item:any,index:any)=>(
                     <div key={index}>
      
                        {
                           item.map((item:any,index:any)=>(
                              <div key={index}  className='  opacity-0 fixed top-0 left-0 pointer-events-none  downloaded'>
                                 <div ref={maskId==index?myResSm:null} className='images relative w-max h-max'>

                                    {item.type==='img'&&<img  className=' object-none '  ref={maskId==index?origImgSm:null}   src={item.src} alt="img" />}
                                    {item.type==='video'&& 
                                    <div  className=' object-none '  >
                                       <video controls ref={maskId==index?origImgSm:null} >
                                          <source  src={item.src} type={item.mime}/>
                                       
                                       </video>                                  
                                    </div>
                                       }
                                 </div>
                              </div>
                           ))
                        
                        }
                     </div>
                     
                  ))
               
            } */}

      <section   className='mx-auto  bigGrid '>
         <div className='pb-2.5 pl-2.5'>
            <Link to='/step_two'  className='flex items-center justify-start w-fit uppercase font-bold'>
               <img className='mr-1' src={`https://promocodes.themultiform.com/app${header.back.img.data.attributes.url}`} alt="back" />
               <span>{header.back.text}</span>
            </Link>
         </div>
         <div className='sm:bg-bgColor-light flex flex-col items-start justify-center placeContainer'>
         <h2 className='sm:text-xl text-lg font-semibold  leading-7  title3 w-full text-center' >{step3.title}</h2>
        
         {step3arr&&
            step3arr.map((item:any,index:any)=>(
               <div key={index} className=' w-full h-full'>

               <div className={item.length>1?' placesBody1 cursor-pointer':' cursor-pointer placesBody2'} >
                  {
                     item.map((item:any,index:any)=>(
                        //.slice(0,4)
                        <div key={index} className='flex flex-col items-start  justify-center  flexPromo1 '>
                           
                           
                           <div id={item.src} data-id={item.src} onClick={(e)=>maskClick(e)} className='block placesCntnr '> 
                              <div   className='imgPlace code-cntnr relative pointer-events-none '>
                                 <div  className='relative w-full h-full' >
                                 {/* onLoad={loading} */}
                                 
                                    {item.type==='img'&&<img className={'imgPlace__img contentPromo'} onLoad={loading}  src={item.src} alt="img" />}
                                    {item.type==='video'&& 
                                    <div className='relative w-full h-full'>
                                       <video className='vidos overflow-hidden w-full h-full object-cover' preload='auto' autoPlay muted loop playsInline>
                                          <source className='contentPromo' src={item.src} type={item.mime}/>
                                       
                                       </video>                                  
                                    </div>
                                       }
                              
                                 </div>
                                 
                              </div>
                           </div>
                           
                           <div id={item.src} className={'mask-bg opacity-0 pointer-events-none duration-300'}>
                              <div className='mask-bg__container'>
                              <div className='flex flex-col items-center justify-center  mask   relative'>
                                 <div id={item.src} onClick={(e)=>maskClick(e)}  className='mask-exit'>X</div>
                                 <div className='flex flex-col items-center justify-center placesCntnr'> 
                                    <div   className='imgPlace relative flex items-start justify-center'>
                                       
                                    {(item.type==='img'&&activeSrc.length)&&<img ref={maskId==item.src?myResSm:null} className={'imgPlace__img '}   src={activeSrc} alt="img" />}
                                    {(item.type==='video')&&
                                    <div  className='relative  flex items-center justify-center '>
                                       <div className='vidos-cntnr'>
                                          <video className='vidos ' preload='auto' autoPlay muted loop playsInline>
                                             <source ref={maskId==item.src?myResSm:null} src={item.src} type={item.mime}/>
                                          
                                          </video> 
                                       </div>
                                                                        
                                    </div>
                                       }
                                          
                                       
                                          
                                    
                                    </div>
                                    
                                 </div>
                                 

                           
                                 <button onClick={onButtonClick} className='bg-green mt-5 mx-auto flex items-center justify-berween w-60 font-extrabold uppercase  btnPromo hover:shadow-md hover:shadow-blue-solight duration-200 '>
                                    <div className='bg-green-dark  flex items-center justify-center btnPromoImg'>
                                       <img className='w-4 h-4' src={`https://promocodes.themultiform.com/app${header.download.img.data.attributes.url}`} alt="arr" />
                                    </div>
                                    <div className='px-10  '>
                                       <span>{lang.includes('ru')?'скачать':'download'}</span>
                                    </div>
                                 
                                 
                                 </button>

                              </div>
                              </div>

                           </div>
                        
                        
                        </div>
                     ))
                  
                  }
               </div>
               
               </div>
            ))
         }
         
         </div>

      
      </section>
   
      
   
</div>
      }
      </>

   )
}

export default StepThree