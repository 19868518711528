//import { Routes, Route } from "react-router-loading";
import { Route, Routes } from 'react-router-dom'
import './App.css'
import Main from './components/Main'
import MainPromo from './promocodePage/MainPromo'
function App() {
  const MyLoadingScreen = () => {
    return (
      <div className='  w-screen h-screen fixed z-30 top-0 left-0  '>
        <div className='  bg-bgColor flex items-center justify-center w-full h-full'>
          {/* <div className=" lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
        </div>
      </div>
    )
  }

  return (
    <div className='App h-full bg-bgColor'>
      <Routes>
        <Route path='/old' element={<Main />} />
        <Route path='/*' element={<MainPromo />} />
      </Routes>
    </div>
  )
}

export default App
