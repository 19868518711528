import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchCount } from './counterAPI';

type StylesT={
  style:{
    id:string
    name:string
    value:string
    units:any
    
  }
  
}
export interface CounterState {
  value: number;
  status: 'idle' | 'loading' | 'failed';
  promoStyles:StylesT[]
  promoStylesText:StylesT[]
  rotateInput:any
  beforeEl:boolean
  defImg: number
  load3:boolean
  lang:string
  loaded:boolean
  mycode:string
  wlsLang:string
  defLang:string
  loadedL:boolean
}

const initialState: CounterState = {
  value: 0,
  status: 'idle',
  beforeEl:true,
  promoStyles:[],
  promoStylesText:[],
  rotateInput:null,
  defImg:0,
  load3:false,
  loaded:true,
  loadedL:true,
  lang:'',
  mycode:'',
  wlsLang:'',
  defLang:''
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    addLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
      //console.log(state.lang)
    },
    addCode: (state, action: PayloadAction<string>) => {
      state.mycode = action.payload;
      
    },
    addDefLang: (state, action: PayloadAction<string>) => {
      state.defLang = action.payload;
      //console.log(state.defLang)
    },
    addWlsLang: (state, action: PayloadAction<string>) => {
      state.wlsLang = action.payload;
      console.log(state.wlsLang)
    },
    incrementDefImg: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.defImg += 1;
    },
    addDefImg: (state, action: PayloadAction<number>) => {
      state.defImg = action.payload;
    },
    addBeforeEl: (state, action: PayloadAction<boolean>) => {
      state.beforeEl = action.payload;
    },
    addLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
      //console.log(state.loaded)
    },
    addLoadedL: (state, action: PayloadAction<boolean>) => {
      state.loadedL = action.payload;
      //console.log(state.loadedL)
    },
    addLoad3: (state, action: PayloadAction<boolean>) => {
      state.load3 = action.payload;
    },

    addPromoStyles: (state, action: PayloadAction<StylesT>) => {
      if(state.promoStyles.length&&state.promoStyles.find((item:any)=>item.style.id===action.payload.style.id)){
        state.promoStyles = [
          ...state.promoStyles.filter((item:any)=>item.style.id!==action.payload.style.id),
          action.payload
        ]
      }else{
        state.promoStyles = [
          ...state.promoStyles,
          action.payload
        ]
      }
      
      
      console.log('promoStyles',state.promoStyles)
    },
    addPromoStylesText: (state, action: PayloadAction<StylesT>) => {
      if(state.promoStylesText.length&&state.promoStylesText.find((item:any)=>item.style.id===action.payload.style.id)){
        state.promoStylesText = [
          ...state.promoStylesText.filter((item:any)=>item.style.id!==action.payload.style.id),
          action.payload
        ]
      }else{
        state.promoStylesText = [
          ...state.promoStylesText,
          action.payload
        ]
      }
      
      
      console.log('promoStylesText',state.promoStylesText)
    },

    addRotateInput: (state, action: PayloadAction<any>) => {
      state.rotateInput=action.payload
      
      
      console.log('rotateInput',state.rotateInput)
    },
 
},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      })
      .addCase(incrementAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {addDefLang,addLoadedL,addWlsLang, addCode,addLoaded,addLang,addLoad3,incrementDefImg,addDefImg,addBeforeEl,addRotateInput,addPromoStylesText,addPromoStyles,increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.counter.value;
export const actualPromoStyles = (state: RootState) => state.counter.promoStyles;
export const actualPromoStylesText = (state: RootState) => state.counter.promoStylesText;
export const actualRotateInput = (state: RootState) => state.counter.rotateInput;
export const actualBeforeEl = (state: RootState) => state.counter.beforeEl;
export const actualDefImg = (state: RootState) => state.counter.defImg;
export const actualLoad3 = (state: RootState) => state.counter.load3;
export const actualLang = (state: RootState) => state.counter.lang;
export const actualLoaded = (state: RootState) => state.counter.loaded;
export const actualCode = (state: RootState) => state.counter.mycode;
export const actualWlsLang = (state: RootState) => state.counter.wlsLang;
export const actualLoadedL = (state: RootState) => state.counter.loadedL;
export const actualDefLang = (state: RootState) => state.counter.defLang;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount));
    }
  };

export default counterSlice.reducer;
