import React,{FC,useEffect,useState} from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { actualRotateInput, addPromoStyles,addPromoStylesText,addRotateInput} from '../features/counter/counterSlice'
import ColPic from './ColPic'

type AnyT={
   settings:any
   onButtonClick:any
   rotateRef:any
}
const Config:FC<AnyT> = ({settings,onButtonClick,rotateRef}) => {
   //const rotateInput = useAppSelector(actualRotateInput)
   const dispatch=useAppDispatch()
   
   useEffect(()=>{
      //dispatch(addRotateInput(rotateInput=e.target))

   },[])

const funcB = (e:any)=>{
   dispatch(addPromoStyles(
         {
            style:{
               id:e.target.id,
               name:e.target.dataset.style,
               value:e.target.value,
               units:e.target.dataset.units
            }
         }
   ))
}

const funcT = (e:any)=>{
   
   dispatch(addPromoStylesText(
         {
            style:{
               id:e.target.id,
               name:e.target.dataset.style,
               value:e.target.value==='По умолчанию'?'none':e.target.value==='Большие'?'uppercase':e.target.value==='Маленькие'?'lowercase':e.target.value,
               units:e.target.dataset.units
            }
         }
   ))
}
   return (
      <div className=' whitespace-nowrap text-sm '>
         <div className='bg-grey-dark py-5 px-2   configCntnr '>
            <p className='uppercase font-bold text-white text-center pt-5'>
               {settings.title}
            </p>

            <div className='pt-5 sm:px-4'>
               <p className='uppercase font-bold text-white text-left'>
                  {settings.background.title} :
               </p>

               <nav>
                  <ul className='text-white text-left'>
                     {
                        settings.background.setting.map((item:any)=>(
                           <li key={item.id} className='py-1'>
                              <div className='flex items-center justify-between'>
                                 <span className='pr-4 w-2/3'>{item.text}</span>
                                 <div className='flex items-center w-1/3'>
                                    {
                                       item.input==='select'?
                                          <select className='w-full text-black h-6 duration-200 hover:shadow-md hover:shadow-blue-solight' name="" id=""></select>
                                       :
                                       <input ref={item.style==='transform'?rotateRef:null} id={item.id} data-style={item.style} data-units={item.units} className='w-full text-black h-6 px-1 duration-200 hover:shadow-md hover:shadow-blue-solight' type={item.input} onChange={(e)=>funcB(e)}/>
                                    }
                                    {item.units&&
                                       <span className='pl-2'>{item.units}</span>
                                    }
                                 </div>
                                    
                                 
                                 
                                 
                              </div>
                              
                           </li>
                        ))
                     }
                  </ul>
               </nav>
               
            </div>

            <div className='pt-5 sm:px-4'>
               <p className='uppercase font-bold text-white text-left'>
                  {settings.textSettings.title} :
               </p>

               <nav>
                  <ul className='text-white text-left'>
                     {
                        settings.textSettings.setting.map((item:any)=>(
                           <li key={item.id} className='py-1'>
                              <div className='flex items-center justify-between'>
                                 <span className='pr-4 w-2/3'>{item.text}</span>
                                 <div className='flex items-center w-1/3'>
                                    {
                                       item.input==='select'?
                                          <select className='w-full text-black h-6  duration-200 hover:shadow-md hover:shadow-blue-solight' name={item.input} onChange={(e)=>funcT(e)} id={item.id} data-style={item.style} data-units={item.units} >
                                             {
                                                item.selectOptions&&

                                                item.selectOptions.split('\n').map((item:string,index:number)=>(
                                                   <option key={index} value={item}>{item}</option>
                                                ))
                                                
                                             }
                                             
                                          </select>
                                       :
                                       <input id={item.id} data-style={item.style} data-units={item.units} className='w-full text-black h-6 px-1 duration-200 hover:shadow-md hover:shadow-blue-solight' type={item.input} onChange={(e)=>funcT(e)}/>
                                    }
                                    {item.units&&
                                       <span className='pl-2'>{item.units}</span>
                                    }
                                 </div>
                                    
                                 
                                 
                                 
                              </div>
                              
                           </li>
                        ))
                     }
                  </ul>
               </nav>
            </div>


            <div className='pt-5 text-white  font-bold text-center btnConfig'>
               <button onClick={onButtonClick} className='bg-blue-light py-2 px-4 rounded hover:shadow-md hover:shadow-blue-solight duration-200'>{settings.button}</button>
            </div>
         </div>
      </div>
   )
}

export default Config